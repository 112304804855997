import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { homeApi } from "../../api";
import Redirect from "../../DetailFaculty/CreateNewTablesData/Redirect";
import { useOktaAuth } from "@okta/okta-react";
import formatDate from "../../../helpers/DateFormatter";
import {appInsights} from "../../../appInsights";

function EditFacultyStatusChanges(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const id = props.tableid;
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState(props.dataTable);
  const [check, setCheck] = useState(props.dataTable.active);

  const handleCheck = () => {
    setCheck(!check);
  };
  const handleChange = ({ target: { name, value } }) => {
    if (name == "ActivityId") {
      props.dropdown.map((value1, i) => {
        if (value == value1.activity) {
          setData({
            ...data,
            activityId: value1.id,
          });
        }
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const postBody = {
      ...data,
      active: check,
      startDate: data.startDate === "null" ? "" : data.startDate,
      activityId: data.activityId === null ? 1 : data.activityId,
      modifiedBy: props.createdBy,
    };

    homeApi
      .putEditFacultyTable(
        "facultystatuschanges",
        postBody,
        id,
        authState.accessToken.value
      )
      .then((res) => {
        setRedirect(true);
        setMessage("200");
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      })
      .catch((e) => {
        setRedirect(true);
        setMessage("400");
        appInsights.trackException({ error: e});
        console.error(e);
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      });
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail">Edit Faculty Status Changes</h2>
        <span className="span-link-btn">
          {" "}
          <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
            Back
          </Link>
        </span>
        <hr className="hr-facultyDetail"></hr>
        {redirect == true && <Redirect message={message} />}
        <Col xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Faculty Member
              </Form.Label>
              <Form.Control
                disabled
                className="activity_form_control"
                defaultValue={props.lastName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Activity
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                name="ActivityId"
                defaultValue={data.activity}
                onChange={handleChange}
              >
                <option>{data.activity}</option>
                {props.dropdown.map((value, i) => (
                  <option key={i} value={value.activity}>
                    {value.activity}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Title Being Proposed
              </Form.Label>

              <Form.Control
                className="activity_form_control"
                name="proposedTitle"
                maxLength="100"
                defaultValue={data.proposedTitle}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Notification Alert
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="notificationAlert"
                maxLength="100"
                defaultValue={
                  data.notificationAlert == "null" ? "" : data.notificationAlert
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Details
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="details"
                maxLength="100"
                defaultValue={data.details === "null" ? "" : data.details}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Start Date
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                type="date"
                defaultValue={
                  Boolean(data.startDate) ? formatDate(data.startDate) : ""
                }
                name="startDate"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                RR
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="rr"
                maxLength="100"
                defaultValue={data.rr === "null" ? "" : data.rr}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Step 1 (status change,promo materials,submitted to AA)
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="step1Dt"
                type="date"
                defaultValue={
                  Boolean(data.step1Dt) ? formatDate(data.step1Dt) : ""
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Submitted to USC
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="uscsubmitDt1"
                type="date"
                defaultValue={
                  Boolean(data.uscsubmitDt1)
                    ? formatDate(data.uscsubmitDt1)
                    : ""
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Approved
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="approved"
                type="date"
                defaultValue={
                  Boolean(data.approved) ? formatDate(data.approved) : ""
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Step 2 (Packet submitted to AA)
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="step2Dt"
                type="date"
                defaultValue={
                  Boolean(data.step2Dt) ? formatDate(data.step2Dt) : ""
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Submitted to USC (2)
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="uscsubmitDt2"
                type="date"
                defaultValue={
                  Boolean(data.uscsubmitDt2)
                    ? formatDate(data.uscsubmitDt2)
                    : ""
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                USC App'd Date
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="uscappDt"
                defaultValue={
                  Boolean(data.uscappDt) ? formatDate(data.uscappDt) : ""
                }
                type="date"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Notes
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                as="textarea"
                rows={3}
                name="notes"
                maxLength="100"
                defaultValue={data.notes === "null" ? "" : data.notes}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Active
              </Form.Label>
              <Col style={{ marginLeft: "15px" }}>
                <Form.Check
                  className="activity_form_control"
                  type="checkbox"
                  name="active"
                  defaultChecked={check}
                  onChange={handleCheck}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col style={{ marginLeft: "inherit" }}>
                <Button className="activity_form_label" type="submit">
                  Save
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default EditFacultyStatusChanges;
