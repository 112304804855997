import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import Faculty from "./Faculty";
import Spinner from "../spinner";

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  // fetch user information once login through OKTA
  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  if (authState.isPending) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      {authState.isAuthenticated && !userInfo && (
        <div
          style={{
            textAlign: "center",
            marginTop: "10%",
            marginBottom: "10%",
          }}
        >
          <Spinner />
        </div>
      )}

      {authState.isAuthenticated && userInfo && (
        <>
          <div className="createNew">
            <a href="/createNewFaculty" className="new">
              Add New
            </a>
          </div>
          <Faculty />
        </>
      )}

      {!authState.isAuthenticated && (
        <h1 className="heading">
          You are not authenticated. Please Login into the application.
        </h1>
      )}
    </div>
  );
};

export default Home;
