import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import { homeApi } from "../../api";
import Spinner from "../../../spinner";
import SliderCustom from "../../DetailFaculty/CreateNewTablesData/Slider";
import Redirect from "../../DetailFaculty/CreateNewTablesData/Redirect";
import { useOktaAuth } from "@okta/okta-react";
import { appInsights } from "../../../appInsights";

const CLINICAL = "Clinical";
const ADMINISTRATION = "Administration";
const RESEARCH = "Research";
const TEACHING = "Teaching";
const label = [CLINICAL, ADMINISTRATION, RESEARCH, TEACHING];

function EditCart(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState([
    props.dataTable.clinicalPercentage,
    props.dataTable.administrationPercentage,
    props.dataTable.researchPercentage,
    props.dataTable.teachingPercentage,
  ]);

  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fiscalYear, setFiscalYear] = useState(
    props.dropdown.filter((year) => year.id === props.dataTable.fiscalYearId)[0]
  );
  const [detail, setDetail] = useState(props.dataTable.notes);

  const id = props.tableid;

  function handleChange(index, value, name) {
    setErrorAlert(false);
    setData((vs) =>
      vs.map((v, i) => {
        if (i === index) return parseFloat(value, 10);
        return parseFloat(v, 10);
      })
    );
  }

  function handleFiscalYearChange({ target: { index, name, value } }) {
    props.dropdown.map((year) => {
      if (value == year.id) {
        return setFiscalYear(year);
      }
    });
  }

  function handleDetailChange({ target: { index, name, value } }) {
    setDetail(value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let arrSum = 0;
    for (let i = 0; i < data.length; i++) {
      arrSum += data[i];
    }
    if (arrSum !== 100) {
      setLoading(false);
      setErrorAlert(true);
    } else {
      const postBody = {
        clinicalPercentage: parseFloat(data[0]),
        administrationPercentage: parseFloat(data[1]),
        researchPercentage: parseFloat(data[2]),
        teachingPercentage: parseFloat(data[3]),
        fiscalYearId: fiscalYear.id,
        modifiedBy: props.createdBy,
        facultyId: props.dataTable.facultyId,
        id: props.tableid,
        notes: detail,
      };
      homeApi
        .putEditFacultyTable("cart", postBody, id, authState.accessToken.value)
        .then((res) => {
          setLoading(false);
          setRedirect(true);
          setMessage("200");
          setTimeout(() => {
            window.location.href = `/detail/${Number(
              props.dataTable.facultyId
            )}`;
          }, 1500);
        })
        .catch((e) => {
          setLoading(false);
          setRedirect(true);
          setMessage("400");
          appInsights.trackException({ error: e });
          console.error(e);
          setTimeout(() => {
            window.location.href = `/detail/${Number(
              props.dataTable.facultyId
            )}`;
          }, 1500);
        });
    }
  };
  if(loading === true){
    return (
      <div className="col loader mb-3">
      <Spinner />
    </div>
    )
  }
  else if (props.dropdown.length === 0) {
    return <Spinner />;
  } else {
    return (
      <React.Fragment>
        <Container maxWidth="lg">
          <h2 className="heading-facultyDetail">Edit Cart</h2>
          <span className="span-link-btn">
            {" "}
            <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
              Back
            </Link>
          </span>
          <hr className="hr-facultyDetail"></hr>
          {redirect == true && <Redirect message={message} />}
          {errorAlert === true ? (
            <Alert variant="danger">All fields need to add up to 100%.</Alert>
          ) : (
            <></>
          )}
          <Col xs={6}>
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Row}>
                <Form.Label column>Fiscal Years *</Form.Label>
                <Form.Control
                  className="activity_form_control"
                  style={{ backgroundColor: "white", appearance: "menulist" }}
                  as="select"
                  name="fiscalYearId"
                  required
                  onChange={handleFiscalYearChange}
                >
                  {props.dropdown !== undefined ? (
                    props.dropdown.map((value, i) => {
                      if (value.period == fiscalYear.period) {
                        return (
                          <option key={i} value={value.id} selected>
                            {value.period}
                          </option>
                        );
                      } else {
                        return (
                          <option key={i} value={value.id}>
                            {value.period}
                          </option>
                        );
                      }
                    })
                  ) : (
                    <></>
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column>Notes</Form.Label>
                <Form.Control
                  maxLength="100"
                  name="Notes"
                  defaultValue={detail}
                  onChange={handleDetailChange}
                />
              </Form.Group>

              {label.map((item, index) => {
                return (
                  <SliderCustom
                    key={index}
                    index={index}
                    title={item}
                    name={item}
                    value={data[index]}
                    onChange={(e) => handleChange(index, e.target.value)}
                  />
                );
              })}
              <Form.Group as={Row}>
                <Col style={{ marginLeft: "inherit" }}>
                  <Button className="activity_form_label" type="submit">
                    Save
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Container>
      </React.Fragment>
    );
  }
}

export default EditCart;
