import React, { useState, useEffect, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import { Form, Row, Col, Button } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { appInsights } from "../../../appInsights";
import { homeApi } from "../../api";
import Redirect from "./Redirect";
import { useOktaAuth } from "@okta/okta-react";

function Promotion(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState({});
  const [checkDept, setCheckDept] = useState(false);
  const [checkVice, setCheckVice] = useState(false);
  const handleCheckVice = () => setCheckVice(!checkVice);
  const handleCheckDept = () => setCheckDept(!checkDept);
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  let btnRef = useRef();

  const handleChange = ({ target: { name, value } }) => {
    setError(false);
    if (error === false) {
      btnRef.current.removeAttribute("disabled");
    }
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "true");
    }
    const postBody = {
      ...data,
      viceApproveDt: checkVice,
      deptApproveDt: checkDept,
      facultyId: Number(props.id),
      createdBy: props.createdBy,
    };

    homeApi
      .postCreateNewFacultyTable(
        "promotions",
        postBody,
        authState.accessToken.value
      )
      .then((res) => {
        setRedirect(true);
        setMessage("200");
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      })
      .catch((e) => {
        setRedirect(true);
        setMessage("400");
        console.error(e);
        appInsights.trackException({ error: e });
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      });
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail"> Add New Promotion</h2>
        <span className="span-link-btn">
          {" "}
          <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
            Back
          </Link>
        </span>
        <hr className="hr-facultyDetail"></hr>
        {redirect == true && <Redirect message={message} />}

        <Col xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Faculty Member
              </Form.Label>
              <Form.Control
                disabled
                className="activity_form_control"
                name="lastName"
                defaultValue={props.lastName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Proposed Title *
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="ProposedTitle"
                required
                maxLength="100"
                defaultValue={data.proposedTitle}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Last Communication
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="lastCommunication"
                type="text"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Salary Increase
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                type="text"
                maxLength="100"
                name="salaryIncrease"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Intro Meeting
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="intromeeting"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Promotion Form
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="promotionForm"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Division letter of support
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="supportLetter"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Job profile
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="jobProfile"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                CV
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="cv"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Statement
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="statement"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Quant Data
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="quantData"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Service Record
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="serviceRecord"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Teaching Record
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="teachingRecord"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Professional letters
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="professionalLetters"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Reprints
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="reprints"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Approved by Vice Chair of FD
              </Form.Label>
              <Col style={{ marginLeft: "-10px" }}>
                <Form.Check
                  className="activity_form_control"
                  type="checkbox"
                  onChange={handleCheckVice}
                  name="viceApproveDt"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Approved by Dept Chair
              </Form.Label>
              <Col style={{ marginLeft: "-10px" }}>
                <Form.Check
                  className="activity_form_control"
                  type="checkbox"
                  onChange={handleCheckDept}
                  name="deptApproveDt"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Submitted by USC
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="uscsubmitDt"
                type="date"
                // max={
                //   data.uscapproveDt !== undefined && data.uscapproveDt !== null
                //     ? data.uscapproveDt.split("T")[0]
                //     : ""
                // }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Approved By USC
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="uscapproveDt"
                type="date"
                // min={
                //   data.uscsubmitDt !== undefined && data.uscsubmitDt !== null
                //     ? data.uscsubmitDt.split("T")[0]
                //     : ""
                // }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Comments
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="comments"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Col style={{ marginLeft: "inherit" }}>
                <Button className="activity_form_label" type="submit" ref={btnRef}>
                  Create
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default Promotion;
