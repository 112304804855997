import { isNotNullOrUndefined } from "@microsoft/applicationinsights-core-js";

export default function DateHelper(date) {
  if (!isNotNullOrUndefined(date)) {
    return date;
  }
  let dd;
  try {
    dd = date.getDate();
  } catch {
    return date;
  }
  let mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }

  return `${yyyy}-${mm}-${dd}`;
}
