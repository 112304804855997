import React, { useState,useRef } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { homeApi } from "../../api";
import { Link } from "react-router-dom";
import Redirect from "./Redirect";
import { useOktaAuth } from "@okta/okta-react";
import {appInsights} from "../../../appInsights";
function Ranks(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState({
    rankId: 1,
    desingationId: 0,
    statusId: 1,
    tenureId: 1,
    trackId: 1,
  });
  const [check, setCheck] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const [detailMessage, setDetailMessage] = useState("");
  const [error, setError] = useState(false);
  let btnRef = useRef();
  const handleCheck = () => {
    setCheck(!check);
  };

  const handleChange = ({ target: { name, value } }) => {
    setError(false);
    if (error === false) {
      btnRef.current.removeAttribute("disabled");
    }
    if (
      name == "rankId" ||
      name == "desingationId" ||
      name == "statusId" ||
      name == "tenureId" ||
      name == "trackId"
    ) {
      setData({
        ...data,
        [name]: Number(value),
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "true");
    }
    const postBody = {
      ...data,
      currentOrLast: check,
      FacultyId: Number(props.id),
      createdBy: props.createdBy,
    };

    homeApi
      .postCreateNewFacultyTable(
        "ranklogs",
        postBody,
        authState.accessToken.value
      )
      .then((res) => {
        setRedirect(true);
        setMessage("200");
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      })
      .catch((e) => {
        setRedirect(true);
        setMessage("400");
        if(e.response.data.message) {
          setDetailMessage(e.response.data.message);
        }
        console.error(e);
        appInsights.trackException({ error: e });
        // setTimeout(() => {
        //   window.location.href = `/detail/${Number(props.id)}`;
        // }, 1500);
      });
  };
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail"> Add New Rank</h2>
        <span className="span-link-btn">
          {" "}
          <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
            Back
          </Link>
        </span>
        <hr className="hr-facultyDetail"></hr>
        {redirect == true && <Redirect message={message} detailMessage={detailMessage} />}
        <Col xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Faculty Member
              </Form.Label>
              <Form.Control disabled defaultValue={props.lastName} />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Current/Last Rank
              </Form.Label>
              <Col style={{ marginLeft: "-10px" }}>
                <Form.Check
                  name="currentOrLast"
                  type="checkbox"
                  onChange={handleCheck}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Rank *
              </Form.Label>
              <Form.Control
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                required
                onChange={handleChange}
                name="rankId"
              >
                {props.dropdownRank.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Tenure *
              </Form.Label>
              <Form.Control
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                name="tenureId"
                required
                onChange={handleChange}
              >
                {props.dropdownTenure.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Track *
              </Form.Label>
              <Form.Control
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                name="trackId"
                required
                onChange={handleChange}
                //   defaultValue={props.activity}
              >
                {props.dropdownTrack.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Designation
              </Form.Label>
              <Form.Control
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                name="desingationId"
                onChange={handleChange}
              >
                {props.dropdownDesignation.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Promotion Date
              </Form.Label>
              <Form.Control
                type="date"
                // max={
                //   data.tenuredDate !== undefined && data.tenuredDate !== null
                //     ? data.tenuredDate.split("T")[0]
                //     : ""
                // }
                name="promotionDate"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Status *
              </Form.Label>
              <Form.Control
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                name="statusId"
                required
                onChange={handleChange}
                //defaultValue={props.activity}
              >
                {props.dropdownStatus.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.status}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                TDD
              </Form.Label>
              <Form.Control name="tdd" type="date" onChange={handleChange} />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Tenured Date
              </Form.Label>
              <Form.Control
                // min={
                //   data.promotionDate !== undefined &&
                //   data.promotionDate !== null
                //     ? data.promotionDate.split("T")[0]
                //     : ""
                // }
                type="date"
                name="tenuredDate"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Notes
              </Form.Label>
              <Form.Control
                maxLength="100"
                onChange={handleChange}
                as="textarea"
                name="notes"
                rows={3}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Col style={{ marginLeft: "inherit" }}>
                <Button className="activity_form_label" type="submit" ref={btnRef}>
                  Create
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default Ranks;
