import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Form, Row, Col, Button } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { homeApi } from "../../api";
import Redirect from "../../DetailFaculty/CreateNewTablesData/Redirect";
import { useOktaAuth } from "@okta/okta-react";
import formatDate from "../../../helpers/DateFormatter";
import {appInsights} from "../../../appInsights";
function EditAdminTitle(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState(props.dataTable);
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const id = props.tableid;
  const handleChange = ({ target: { name, value } }) => {
    if (name === "titleTypeId") {
      setData({
        ...data,
        [name]: Number(value),
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const postBody = {
      ...data,

      modifiedBy: props.createdBy,
    };

    homeApi
      .putEditFacultyTable(
        "admintitles",
        postBody,
        id,
        authState.accessToken.value
      )
      .then((res) => {
        setRedirect(true);
        setMessage("200");
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      })
      .catch((e) => {
        setRedirect(true);
        setMessage("400");
        appInsights.trackException({ error: e});
        console.error(e);
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      });
  };
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail">Edit Admin Title</h2>
        <span className="span-link-btn">
          {" "}
          <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
            Back
          </Link>
        </span>
        <hr className="hr-facultyDetail"></hr>
        {redirect == true && <Redirect message={message} />}
        <Col xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Faculty Member Last Name
              </Form.Label>
              <Form.Control
                disabled
                className="activity_form_control"
                name="lastName"
                defaultValue={props.lastName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                New Title *
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="title"
                maxLength="60"
                defaultValue={data.title}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Admin Title Type *
              </Form.Label>
              <Form.Control
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                required
                onChange={handleChange}
                name="titleTypeId"
              >
                <option>{data.titleType}</option>
                {props.dropdownAdminTitleType.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Start Date
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="startDate"
                type="date"
                // max={Boolean(data.endDate) ? formatDate(data.endDate) : ""}
                defaultValue={
                  Boolean(data.startDate) ? formatDate(data.startDate) : ""
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                End Date
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                type="date"
                // min={Boolean(data.startDate) ? formatDate(data.startDate) : ""}
                name="endDate"
                defaultValue={
                  Boolean(data.endDate) ? formatDate(data.endDate) : ""
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Stipend
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="stipend"
                defaultValue={data.stipend}
                onChange={handleChange}
                type="number"
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Col style={{ marginLeft: "inherit" }}>
                <Button className="activity_form_label" type="submit">
                  Save
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default EditAdminTitle;
