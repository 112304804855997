import React from "react";
// import * as RiIcons from "react-icons/ri";

export const MenuItems = {
  title: "Drop Down Menus",
  path: "/",
  // iconClosed: <RiIcons.RiArrowDownSFill />,
  // iconOpened: <RiIcons.RiArrowUpSFill />,
  subNav: [
    {
      title: "Activity Types",
      path: "/activityType",
    },
    {
      title: "Admin Title Type",
      path: "/administrativetitletype",
    },
    {
      title: "Affinity Group",
      path: "/affinitygroup",
    },
    {
      title: "Gender",
      path: "/genders",
    },
    {
      title: "Departments",
      path: "/departments",
    },
    {
      title: "Designations",
      path: "/designations",
    },
    {
      title: "Divisions",
      path: "/divisions",
    },
    {
      title: "Employment Types",
      path: "/employmentType",
    },
    {
      title: "Ethnicities",
      path: "/ethnicities",
    },
    {
      title: "Faculty Types",
      path: "/facultyTypes",
    },
    {
      title: "Ranks",
      path: "/ranks",
    },
    {
      title: "Statuses",
      path: "/employmentstatus",
    },
    {
      title: "Tenures",
      path: "/tenures",
    },
    {
      title: "Tracks",
      path: "/tracks",
    },
    {
      title: "Fiscal Years",
      path: "/fiscalyears",
    },
  ],
};
