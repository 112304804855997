import React, { useState,useRef } from "react";

import { Form, Row, Col, Button } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { homeApi } from "../../api";
import Redirect from "./Redirect";
import { useOktaAuth } from "@okta/okta-react";
import { appInsights } from "../../../appInsights";

function FacultyStatusChanges(props) {
  const { authState, oktaAuth } = useOktaAuth();

  const [data, setData] = useState({ ActivityId: 1 });
  const [check, setCheck] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  let btnRef = useRef();
  const handleCheck = () => {
    setCheck(!check);
  };
  const handleChange = ({ target: { name, value } }) => {
    setError(false);
    if (error === false) {
      btnRef.current.removeAttribute("disabled");
    }
    if (name == "ActivityId") {
      props.dropdown.map((value1, i) => {
        if (value == value1.activity) {
          setData({
            ...data,
            ActivityId: value1.id,
          });
        }
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "true");
    }
    const postBody = {
      ...data,
      active: check,
      FacultyId: Number(props.id),
      createdBy: props.createdBy,
    };

    homeApi
      .postCreateNewFacultyTable(
        "facultystatuschanges",
        postBody,
        authState.accessToken.value
      )
      .then((res) => {
        setRedirect(true);
        setMessage("200");
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      })
      .catch((e) => {
        setRedirect(true);
        setMessage("400");
        console.error(e);
        appInsights.trackException({ error: e });
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      });
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail">
          Add New Faculty Status Changes
        </h2>
        <span className="span-link-btn">
          {" "}
          <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
            Back
          </Link>
        </span>
        <hr className="hr-facultyDetail"></hr>
        {redirect == true && <Redirect message={message} />}
        <Col xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label column>Faculty Member</Form.Label>
              <Form.Control
                disabled
                className="activity_form_control"
                defaultValue={props.lastName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Activity</Form.Label>
              <Form.Control
                className="activity_form_control"
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                name="ActivityId"
                defaultValue={props.activity}
                onChange={handleChange}
              >
                {props.dropdown.map((value, i) => (
                  <option key={i} value={value.activity}>
                    {value.activity}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Title Being Proposed</Form.Label>
              <Form.Control
                className="activity_form_control"
                name="ProposedTitle"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Notification Alert</Form.Label>
              <Form.Control
                className="activity_form_control"
                name="NotificationAlert"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Details</Form.Label>
              <Form.Control
                className="activity_form_control"
                name="Details"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Start Date</Form.Label>
              <Form.Control
                className="activity_form_control"
                type="date"
                name="StartDate"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>RR</Form.Label>
              <Form.Control
                className="activity_form_control"
                name="Rr"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>
                Step 1 (status change,promo materials,submitted to AA)
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="step1Dt"
                type="date"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Submitted to USC</Form.Label>
              <Form.Control
                className="activity_form_control"
                name="uscsubmitDt1"
                type="date"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Approved</Form.Label>
              <Form.Control
                className="activity_form_control"
                name="Approved"
                type="date"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Step 2 (Packet submitted to AA)</Form.Label>
              <Form.Control
                className="activity_form_control"
                name="Step2Dt"
                type="date"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Submitted to USC(2)</Form.Label>
              <Form.Control
                className="activity_form_control"
                name="UscsubmitDt2"
                type="date"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>USC App'd Date</Form.Label>
              <Form.Control
                className="activity_form_control"
                name="UscappDt"
                type="date"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Notes</Form.Label>
              <Form.Control
                className="activity_form_control"
                as="textarea"
                rows={3}
                name="Notes"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column lg={4}>
                Active
              </Form.Label>
              <Col>
                <Form.Check
                  style={{ marginLeft: "25px" }}
                  className="activity_form_control"
                  type="checkbox"
                  defaultChecked={check}
                  name="Active"
                  onChange={handleCheck}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col lg={6}>
                <Button type="submit" ref={btnRef}>Create</Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default FacultyStatusChanges;
