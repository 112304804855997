let configuration;
let API_URL = process.env.REACT_APP_API_ENDPOINT
let issuer = 'https://oktalogin.chla.org/oauth2/aus77svjdiCvaf9cC2p7';
let clientId = '0oab2x5o4t9beCeum2p7';
let redirectUri = window.location.origin + '/implicit/callback';
let scope = ['openid', 'email', 'profile', 'FacultyTracker'];
const GENDERS = 'genders';
const ETHNICITIES = 'ethnicities';
const AFFINITYGROUPS = "affinityGroups";
const DEPARTMENTS = 'departments';
const FACULTYTYPES = 'facultyTypes';
const DIVISIONS = 'divisions';
const EMPLOYMENTTYPES = 'employmentTypes';
const FISCALYEARS = 'fiscalYears';
const MENTORS='mentors';
const TENURES='tenures';
const TRACKS='tracks';
const DESIGNATIONS='designations';
const RANKS='ranks'
const STATUS='status'
const ADMINTITLETYPE='administrativeTitleTypes'



// if (process.env.NODE_ENV === 'development') {
// 	configuration = 'http://localhost:3004';
// 	API_URL = "https://api-appdev-faculty-tracker-test-001.ase-eapps-test-001.p.azurewebsites.net/api/"
// } else if (process.env.NODE_ENV === 'production') {
// 	configuration = 'http://localhost:3004';
// 	API_URL = "https://api-appdev-faculty-tracker-test-001.ase-eapps-test-001.p.azurewebsites.net/api/"
// }
export { configuration, API_URL, issuer, clientId, redirectUri, scope, GENDERS,STATUS,ETHNICITIES, DEPARTMENTS,FACULTYTYPES, RANKS,DIVISIONS, EMPLOYMENTTYPES,MENTORS,TENURES,TRACKS,DESIGNATIONS,ADMINTITLETYPE,AFFINITYGROUPS,FISCALYEARS };
