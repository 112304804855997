import React from "react";
import Typography from "@material-ui/core/Typography";
// import Container from "@material-ui/core/Container";
import { Checkbox } from "@material-ui/core";
import { Table, Container, Form, Button, Text } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../components/styles.css";
import "./layout.css";
import "../FacultyMember/facultyMember.css";
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.rowData,
    };
  }

  render() {
    return (
      <React.Fragment>
        <Container maxWidth="lg" style={{ backgroundColor: "#F5F5F5" }}>
          <h2
            className="heading-facultyDetail"
            data-testid="activity"
            style={{ color: "#000000" }}
          >
            {this.props.headData}
          </h2>
          <span className="span-link-btn-dropdown">
            <Link
              className="new"
              to={{
                pathname: `/${this.props.header}/createnew`,
                aboutProps: {},
              }}
            >
              Add new
            </Link>
          </span>

          <hr className="hr-facultyDetail"></hr>

          <Table
            bordered
            size="sm"
            className="table"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <th style={{ fontWeight: "bold" }}>{this.props.headData}</th>
                <th style={{ fontWeight: "bold" }}>Created On</th>
                <th style={{ fontWeight: "bold" }}>Created By</th>
                <th style={{ fontWeight: "bold" }}>Modified On</th>
                <th style={{ fontWeight: "bold" }}>Modified By</th>
                <th style={{ fontWeight: "bold" }}>Active</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {this.props.rowData.map((item, key) => {
                return (
                  <tr key={key}>
                    {this.props.keyData.map((index) => {
                      return (
                        <td
                          style={{
                            wordWrap: "break-word",
                            color: "#333333",
                            fontWeight: "bold",
                          }}
                          className="dropdownList"
                          data-name={this.props.headData}
                          key={index}
                        >
                          {typeof item[index] === "string" &&
                          item[index].length > 20 ? (
                            <span className="droplist-edit-btn">
                              {item[index]}
                            </span>
                          ) : (
                            item[index]
                          )}
                        </td>
                      );
                    })}
                    <td className="dropdownList">
                      <Form.Check
                        checked={item.active}
                        disabled
                        color="primary"
                      />
                    </td>

                    <td className=" sp-link" style={{ margin: "0 auto" }}>
                      <Link
                        to={{
                          pathname: `/${this.props.header}/edit/${item.id}`,
                          state: { data: item },
                        }}
                        style={{
                          textDecorationSkip: "none",
                          display: "grid",
                          placeItems: "center",
                        }}
                      >
                        <Button type="button" className="edit_dropdown">
                          <p style={{ textAlign: "center" }}>Edit</p>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      </React.Fragment>
    );
  }
}

export default Layout;
