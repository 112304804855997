import React from "react";
import { useState, useEffect } from "react";
import Layout from "./Layout";
import { homeApi } from "../api";
import Spinner from "../../../src/spinner";
import { useOktaAuth } from "@okta/okta-react";
import {appInsights} from "../../appInsights";
function FiscalYears() {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      homeApi
        .getDropdownDetails("fiscalyears", authState.accessToken.value)
        .then((res) => {
          setData(res);
        })
        .catch((e) => {
          appInsights.trackException({ error: e});
          console.error(e);
        });
    }
    fetchData();
  }, []);
  if (data === undefined || Object.keys(data).length === 0) {
    return <Spinner />;
  } else {
    return (
      <>
        <Layout
          headData="Fiscal Years"
          rowData={data.reverse()}
          header="fiscalyears"
          keyData={[
            "period",
            "createdOn",
            "createdBy",
            "modifiedOn",
            "modifiedBy",
          ]}
        />
      </>
    );
  }
}

export default FiscalYears;
