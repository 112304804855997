import React, { useContext, useState } from "react";
// import { Link } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { DetailContext } from "../../context/DetailContext";
import { convertCamelCase } from "../../helpers/CaseHelper";

import "../styles.css";

const FacultyDisplayTable = ({ tableName, tableData }) => {
  let tableNameDisplay;
  if (tableName === "Medical/Graduate School") {
    tableNameDisplay = "medicalSchool";
  } else if (tableName === "Key Info for OOAA Staff") {
    tableNameDisplay = "accomplishments";
  } else if (tableName === "Medical School/ Graduate Program") {
    tableNameDisplay = "medicalSchool";
  } else {
    tableNameDisplay = tableName;
  }

  const { detailList, userList, id } = useContext(DetailContext);
  // const convertCamelCase = (value) => {
  //   var result = value.replace(/([A-Z])/g, " $1");
  //   var changedValue = result.charAt(0).toUpperCase() + result.slice(1);
  //   changedValue = changedValue.replace(/([A-Z])/g, "$1");
  //   return changedValue;
  // };

  return (
    <div className="well sub-well">
      <h3 className="display-header">{convertCamelCase(tableName)}</h3>
      {tableName !== "terminations" && (
        <p className="pull-right">
          <a
            style={{ color: "#004877" }}
            href={`/${tableNameDisplay}/create/${id}`}
          >
            Add New
          </a>
        </p>
      )}
      <Table bordered className="table  bordered" id="table-responsive-display">
        <tbody>
          <tr
            style={{ border: "1px solid #EEEEEE", backgroundColor: "#EEEEEE" }}
          >
            {tableData.length > 0 ? (
              Object.keys(tableData[0])
                .filter((res) => {
                  return (
                    res !== "id" &&
                    res !== "facultyId" &&
                    res !== "promotionDate" &&
                    (tableName === "cart" ? (
                      res !== "active" &&
                      res !== "details" &&
                      res !== "fiscalYearId"
                    ) : (
                      <></>
                    ))
                  );
                })
                .map((key) => (
                  <>
                    {key === "period" ? (
                      <th style={{ fontWeight: "bold", padding: "4px" }}>
                        Fiscal Year
                      </th>
                    ) : (
                      <th style={{ fontWeight: "bold", padding: "4px" }}>
                        {convertCamelCase(key)}
                      </th>
                    )}
                  </>
                ))
            ) : (
              <div
                style={{ padding: "20px", color: "#333333", fontWeight: "600" }}
              >
                No Details
              </div>
            )}
            <th></th>
          </tr>

          {tableData.map((value) => {
            return (
              <tr>
                {Object.entries(value)
                  .filter(
                    (res) =>
                      res[0] !== "id" &&
                      res[0] !== "facultyId" &&
                      res[0] !== "fiscalYearId" &&
                      res[0] !== "promotionDate"
                  )
                  .map((field) => {
                    const key = field[0];
                    let value = field[1];
                    return (
                      <>
                        {typeof value === "boolean" ? (
                          <>
                            {tableName === "cart" ? (
                              <></>
                            ) : (
                              <td
                                data-title={convertCamelCase(key)}
                                className="displayFacultyList"
                                style={{
                                  fontWeight: "600",
                                  paddingTop: "1.1rem",
                                  paddingBottom: "1rem",
                                  color: "#333333",
                                }}
                              >
                                <input
                                  defaultChecked={value}
                                  className="check-box"
                                  disabled="disabled"
                                  type="checkbox"
                                />
                              </td>
                            )}
                          </>
                        ) : (
                          <>
                            {tableName === "cart" && key.includes("details") ? (
                              <></>
                            ) : (
                              <>
                                <td
                                  className="displayFacultyList"
                                  data-title={convertCamelCase(key)}
                                  style={{
                                    fontWeight: "600",
                                    wordWrap: "break-word",
                                    paddingTop: "1.1rem",
                                    paddingBottom: "1rem",
                                    color: "#333333",
                                  }}
                                >
                                  {value}
                                </td>
                              </>
                            )}
                          </>
                        )}
                      </>
                    );
                  })}
                <td
                  className="displayFacultyList"
                  style={{ fontWeight: "600" }}
                >
                  <Link
                    className="link-btn-display"
                    to={{
                      pathname: `/${tableNameDisplay}/editTable/${value.id}`,
                      state: { dataTable: value, id: id, tableid: value.id },
                    }}
                  >
                    Edit
                  </Link>
                  <span className="span-sp"></span>
                  <Link
                    className="link-btn-display"
                    to={{
                      pathname: `/${tableNameDisplay}/detailTable/${value.id}`,
                      state: {
                        tableName: tableNameDisplay,
                        detailList: detailList,
                        dataTable: value,
                        tableid: value.id,
                        id: id,
                      },
                      id: { id },
                    }}
                  >
                    Details
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default FacultyDisplayTable;
