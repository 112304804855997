import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Form, Row, Col, Button } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { homeApi } from "../../api";
import Redirect from "../../DetailFaculty/CreateNewTablesData/Redirect";
import formatDate from "../../../helpers/DateFormatter";
import { appInsights } from "../../../appInsights";

import { useOktaAuth } from "@okta/okta-react";
function EditRanks(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState(props.dataTable);
  const [check, setCheck] = useState(props.dataTable.currentOrLast);
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const [detailMessage, setDetailMessage] = useState("");

  const id = props.tableid;
  const handleCheck = () => {
    setCheck(!check);
  };

  const handleChange = ({ target: { name, value } }) => {
    if (
      name == "rankId" ||
      name == "desingationId" ||
      name == "statusId" ||
      name == "tenureId" ||
      name == "trackId"
    ) {
      setData({
        ...data,
        [name]: Number(value),
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const postBody = {
      ...data,
      currentOrLast: check,
      rankId: data.rankId === null ? 1 : data.rankId,
      desingationId: data.desingationId === null ? 1 : data.desingationId,
      statusId: data.statusId === null ? 1 : data.statusId,
      tenureId: data.tenureId === null ? 1 : data.tenureId,
      trackId: data.trackId === null ? 1 : data.trackId,
      modifiedBy: props.createdBy,
    };

    homeApi
      .putEditFacultyTable(
        "ranklogs",
        postBody,
        id,
        authState.accessToken.value
      )
      .then((res) => {
        setRedirect(true);
        setMessage("200");
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 3000);
      })
      .catch((e) => {
        setRedirect(true);
        setMessage("400");
        if(e.response.data.message) {
          setDetailMessage(e.response.data.message);
        }
        appInsights.trackException({ error: e});
        console.error(e);
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 3000);
      });
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail">Edit Rank</h2>
        <span className="span-link-btn">
          {" "}
          <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
            Back
          </Link>
        </span>
        <hr className="hr-facultyDetail"></hr>
        {redirect == true && <Redirect message={message} detailMessage={detailMessage}  />}
        <Col xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Faculty Member
              </Form.Label>
              <Form.Control
                disabled
                className="activity_form_control"
                defaultValue={props.lastName}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Current/Last Rank
              </Form.Label>
              <Col style={{ marginLeft: "-10px" }}>
                <Form.Check
                  className="activity_form_control"
                  name="currentOrLast"
                  type="checkbox"
                  defaultChecked={data.currentOrLast}
                  onChange={handleCheck}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Rank *
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                required
                defaultValue={data.rankId}
                onChange={handleChange}
                name="rankId"
              >
                <option>{data.rank}</option>
                {props.dropdownRank.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Tenure *
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                name="tenureId"
                defaultValue={data.tenureId}
                onChange={handleChange}
                required
                //defaultValue={props.activity}
              >
                <option>{data.tenure}</option>
                {props.dropdownTenure.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Track *
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                name="trackId"
                required
                defaultValue={data.trackId}
                onChange={handleChange}
                //   defaultValue={props.activity}
              >
                <option>{data.track}</option>
                {props.dropdownTrack.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Designation
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                name="desingationId"
                defaultValue={data.desingationId}
                onChange={handleChange}
                //   defaultValue={props.activity}
              >
                <option>{data.designation}</option>
                {props.dropdownDesignation.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Promotion Date
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                type="date"
                defaultValue={
                  Boolean(data.promotionDate)
                    ? formatDate(data.promotionDate)
                    : ""
                }
                name="promotionDate"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Status *
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                required
                defaultValue={data.statusId}
                name="statusId"
                onChange={handleChange}
                //defaultValue={props.activity}
              >
                <option>{data.status}</option>
                {props.dropdownStatus.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.status}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                TDD
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="tdd"
                type="date"
                defaultValue={Boolean(data.tdd) ? formatDate(data.tdd) : ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Tenured Date
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                type="date"
                defaultValue={
                  Boolean(data.tenuredDate) ? formatDate(data.tenuredDate) : ""
                }
                name="tenuredDate"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Notes
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                onChange={handleChange}
                as="textarea"
                maxLength="100"
                defaultValue={data.notes === "null" ? "" : data.notes}
                name="notes"
                rows={3}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Col style={{ marginLeft: "inherit" }}>
                <Button className="activity_form_label" type="submit">
                  Save
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default EditRanks;
