import { formControlClasses } from "@material-ui/core";
import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import "../styles.css";

class UploadAttachment extends React.Component {
  constructor(props) {
    super(props);
    this.state = { file: "", imagePreviewUrl: "" };
  }

  _handleImageChange(e) {
    const { files } = this.props;
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    if (file) {
      reader.readAsDataURL(file);
      files(file);
    }
  }

  render() {
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    let pathName = window.location.pathname;
    let edit = false;
    if (pathName.indexOf("edit") > -1) {
      edit = true;
    }

    if (imagePreviewUrl) {
      $imagePreview = (
        <img className="col-md-8 col-sm-2 imageUpload" src={imagePreviewUrl} />
      );
    } else {
      $imagePreview = <></>;
    }
    return (
      <div>
        <Form.Group as={Row}>
          <Col column>
            <Form.File
              className={edit ? "edit-formfile" : ""}
              label="Photo"
              onChange={(e) => this._handleImageChange(e)}
            />
          </Col>
        </Form.Group>
        <div className="imgPreview ">{$imagePreview}</div>
      </div>
    );
  }
}

export default UploadAttachment;
