import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { homeApi } from "../../api";
import Redirect from "../../DetailFaculty/CreateNewTablesData/Redirect";
import { useOktaAuth } from "@okta/okta-react";
import formatDate from "../../../helpers/DateFormatter";
import ConfirmEditTerminationModal from "../ConfirmEditTerminationModal";
import { appInsights } from "../../../appInsights";

function EditTermination(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState(props.dataTable);
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const id = props.tableid;
  const [check, setCheck] = useState(props.dataTable.vf);
  const [showModal, setShowModal] = useState(false);

  const handleCheck = () => {
    setCheck(!check);
  };

  const handleChange = ({ target: { name, value } }) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleConfirmEdit = () => {
    handleModalClose();
    const postBody = {
      ...data,
      vf: check,
      modifiedBy: props.createdBy,
    };

    homeApi
      .putEditFacultyTable(
        "terminations",
        postBody,
        id,
        authState.accessToken.value
      )
      .then((res) => {
        setRedirect(true);
        setMessage("200");
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      })
      .catch((e) => {
        setRedirect(true);
        setMessage("400");
        appInsights.trackException({ error: e });
        console.error(e);
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleModalOpen();
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail">Edit Termination Record</h2>
        <span className="span-link-btn">
          {" "}
          <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
            Back
          </Link>
        </span>
        <hr className="hr-facultyDetail"></hr>
        {redirect == true && <Redirect message={message} />}
        <Col xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Faculty Member
              </Form.Label>
              <Form.Control
                disabled
                className="activity_form_control"
                name="lastName"
                defaultValue={props.lastName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Termination Date *
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                type="date"
                name="termDate"
                required
                defaultValue={
                  Boolean(data.termDate) ? formatDate(data.termDate) : ""
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Previous Title Held
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="previousTitle"
                defaultValue={
                  data.previousTitle === "null" ? "" : data.previousTitle
                }
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Notes
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                as="textarea"
                rows={3}
                name="notes"
                maxLength="100"
                defaultValue={data.notes === "null" ? "" : data.notes}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                VF
              </Form.Label>
              <Col style={{ marginLeft: "-10px" }}>
                <Form.Check
                  className="activity_form_control"
                  type="checkbox"
                  name="vf"
                  defaultChecked={data.vf}
                  onChange={handleCheck}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                VF Apt Date
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                type="date"
                name="vfAptDate"
                defaultValue={
                  Boolean(data.vfAptDate) ? formatDate(data.vfAptDate) : ""
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Col style={{ marginLeft: "inherit" }}>
                <Button className="activity_form_label" type="submit">
                  Save
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Container>
      <ConfirmEditTerminationModal
        show={showModal}
        handleClose={handleModalClose}
        handleConfirm={() => handleConfirmEdit()}
        facultyId={props.id}
      />
    </React.Fragment>
  );
}

export default EditTermination;
