import React from "react";
import { Route, useHistory, Switch } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import Home from "./components/Home";
import Faculty from "./components/Faculty";
import NavbarComponent from "./components/Navbar/Navbar";
import ActivityTypes from "./components/DropDown/ActivityTypes";
import { issuer, clientId, redirectUri, scope } from "./config";
import "./App.css";
import Users from "./components/Users/Users";
import Departments from "./components/DropDown/Department";
import EmploymentTypes from "./components/DropDown/EmploymentTypes";
import Edit from "./components/DropDown/Edit";
import CreateNew from "./components/DropDown/CreateNew";
import CreateNewFaculty from "./components/CreateNew/CreateNewFaculty";
import Designations from "./components/DropDown/Designations";
import Divisions from "./components/DropDown/Divisions";
import Ethnicities from "./components/DropDown/Ethnicities";
import Gender from "./components/DropDown/Gender";
import FiscalYears from "./components/DropDown/FiscalYears";
import FacultyTypes from "./components/DropDown/FacultyTypes";
import FacultyMemberDetail from "./components/DetailFaculty/FacultyMemberDetail";
import CreateNewTable from "./components/DetailFaculty/createNewTable";
import Detail from "./components/DetailFaculty/DetailTableData/Detail";
import EditFaculty from "./components/DetailFaculty/EditFaculty";
import Ranks from "./components/DropDown/Ranks";
import Statuses from "./components/DropDown/Statuses";
import Tracks from "./components/DropDown/Tracks";
import Tenures from "./components/DropDown/Tenures";
// import ActivityForm from './components/CreateNew/ActivityForm';
import EditNewTable from "./components/DetailFaculty/EditNewTable";
import CreateNewFormTable from "./components/DetailFaculty/CreateNewFormTable";
import AdminTitleType from "./components/DropDown/AdminTitleType";
import AffinityGroup from "./components/DropDown/AffinityGroup";

const oktaAuth = new OktaAuth({
  issuer: issuer,
  clientId: clientId,
  redirectUri: redirectUri,
  scopes: scope,
  // pkce: true
});
const App = () => {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <NavbarComponent />

      <Switch className="container">
        <Route path="/" exact={true} component={Home} />
        <SecureRoute exact path="/faculty" component={Faculty} />
        <SecureRoute
          exact
          path="/createNewFaculty"
          component={CreateNewFaculty}
        />
        <SecureRoute exact path="/:types/createnew" component={CreateNew} />
        <SecureRoute exact path="/detail/:id" component={FacultyMemberDetail} />
        <SecureRoute exact path="/edit/:id" component={FacultyMemberDetail} />
        <SecureRoute exact path="/:type/edit/:id" component={Edit} />
        <SecureRoute
          exact
          path="/:type/editTable/:id"
          component={EditNewTable}
        />
        <SecureRoute
          exact
          path="/:type/create/:id"
          component={CreateNewFormTable}
        />
        <SecureRoute
          exact
          path="/:type/create/:id"
          component={CreateNewTable}
        />
        <SecureRoute exact path="/:type/detailTable/:id" component={Detail} />
        <SecureRoute exact path="/users" component={Users} />
        <SecureRoute path="/activitytype" exact component={ActivityTypes} />
        <SecureRoute path="/departments" exact component={Departments} />
        <SecureRoute path="/designations" exact component={Designations} />
        <SecureRoute path="/divisions" exact component={Divisions} />
        <SecureRoute path="/employmentType" exact component={EmploymentTypes} />
        <SecureRoute path="/ethnicities" exact component={Ethnicities} />
        <SecureRoute path="/genders" exact component={Gender} />
        <SecureRoute path="/fiscalYears" exact component={FiscalYears} />
        <SecureRoute path="/facultyTypes" exact component={FacultyTypes} />
        <SecureRoute path="/ranks" exact component={Ranks} />
        <SecureRoute path="/employmentstatus" exact component={Statuses} />
        <SecureRoute path="/tenures" exact component={Tenures} />
        <SecureRoute path="/tracks" exact component={Tracks} />
        <SecureRoute
          path="/administrativetitletype"
          exact
          component={AdminTitleType}
        />
        <SecureRoute path="/affinitygroup" exact component={AffinityGroup} />
        <Route path="/implicit/callback" component={LoginCallback} />
      </Switch>
    </Security>
  );
};

export default App;
