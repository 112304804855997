import axios from "axios";
import { configuration, API_URL } from "../../config";

const DOMAIN = configuration;

export async function getFacultyMemberDetails(searchedName, accessToken) {
  const responseData = await axios({
    url: `${API_URL}faculty/?name=${searchedName}`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((result) => {
    return result.data;
  });
  return responseData;
}

export async function getAllFacultyDetails(accessToken) {
  const responseData = await axios({
    url: `${API_URL}allfaculty`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((result) => {
    return result.data;
  });
  return responseData;
}

export async function getDropdownDetails(url, accessToken) {
  const responseData = await axios({
    url: `${API_URL}${url}`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((result) => {
    return result.data;
  });
  return responseData;
}

export async function postDropdownDetails(url, data, accessToken) {
  const responseData = await axios({
    url: `${API_URL}${url}`,
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  }).then((result) => {
    return result.data;
  });
  return responseData;
}

export async function getDropDownFormDataList(accessToken) {
  const responseData = await axios({
    url: `${API_URL}formdropdowndata`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((result) => {
    return result.data;
  });
  return responseData;
}

export async function postDropDownFormDataList(data, accessToken) {
  const responseData = await axios({
    url: `${API_URL}dropdownfields`,
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  }).then((result) => {
    return result.data;
  });
  return responseData;
}

export async function getFacultyDetail(id, accessToken) {
  const responseData = await axios({
    url: `${API_URL}facultyDetails/${id}`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((result) => {
    return result.data;
  });
  return responseData;
}
export async function getFacultyStatusChanges(id, url, accessToken) {
  const responseData = await axios({
    url: `${API_URL}${url}/${id}`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((result) => {
    return result.data;
  });
  return responseData;
}

export async function getFacultyDetailTable(id, facultyTable, accessToken) {
  const responseData = await axios({
    url: `${API_URL}${facultyTable}/${id}`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((result) => {
    return result.data;
  });
  return responseData;
}

export async function putDropdownDetails(url, data, accessToken) {
  const responseData = await axios({
    url: `${API_URL}${url}`,
    method: "put",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  }).then((result) => {
    return result.data;
  });
  return responseData;
}
export async function putEditFacultyTable(url, data, id, accessToken) {
  const formData = new FormData();
  Object.keys(data)
    .filter((d) => data[d] != null)
    .forEach((key) => formData.append(key, data[key]));
  const responseData = await axios({
    url: `${API_URL}${url}/${id}`,
    method: "put",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  }).then((result) => {
    return result.data;
  });
  return responseData;
}

export async function createNewUser(data, accessToken) {
  const responseData = await axios({
    url: `${API_URL}faculty`,
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  }).then((result) => {
    return result.data;
  });
  return responseData;
}

export async function putfacultyDetails(id, data, accessToken) {
  const responseData = await axios({
    url: `${API_URL}facultyDetails/${id}`,
    method: "put",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  }).then((result) => {
    return result.data;
  });
  return responseData;
}

export async function getCreateNewFacultyTable(id, accessToken) {
  const responseData = await axios({
    url: `${API_URL}promotions/${id}`,
    method: "get",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((result) => {
    return result.data;
  });
  return responseData;
}

export async function postCreateNewFacultyTable(url, data, accessToken) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  const responseData = await axios({
    url: `${API_URL}${url}`,
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: formData,
  }).then((result) => {
    return result.data;
  });

  return responseData;
}

export async function postTerminateFaculty(data, accessToken) {
  const responseData = await axios({
    url: `${API_URL}faculty/terminate`,
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  })
    .then((result) => {
      return result.data;
    })
    .catch((e) => {
      throw e;
    });

  return responseData;
}

export async function postRejoinFaculty(data, accessToken) {
  const responseData = await axios({
    url: `${API_URL}faculty/rejoin`,
    method: "post",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: data,
  })
    .then((result) => {
      return result.data;
    })
    .catch((e) => {
      throw e;
    });

  return responseData;
}
