import React, { useState,useRef} from "react";
import Typography from "@material-ui/core/Typography";
import { Form, Row, Col, Button } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { homeApi } from "../../api";
import Redirect from "./Redirect";
import { useOktaAuth } from "@okta/okta-react";
import {appInsights} from "../../../appInsights";
function Termination(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState({});
  const [check, setCheck] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);

  let btnRef = useRef();

  const handleCheck = () => {
    setCheck(!check);
  };

  const handleChange = ({ target: { name, value } }) => {
    setError(false);
    if (error === false) {
      btnRef.current.removeAttribute("disabled");
    }
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "true");
    }
    const postBody = {
      ...data,
      vf: check,
      FacultyId: Number(props.id),
      createdBy: props.createdBy,
      startDate: props.startDate,
    };

    homeApi
      .postCreateNewFacultyTable(
        "terminations",
        postBody,
        authState.accessToken.value
      )
      .then((res) => {
        setRedirect(true);
        setMessage("200");
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      })
      .catch((e) => {
        setRedirect(true);
        setMessage("400");
        console.error(e);
        appInsights.trackException({ error: e });
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      });
  };
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail"> Add New Termination Record</h2>
        <span className="span-link-btn">
          {" "}
          <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
            Back
          </Link>
        </span>
        <hr className="hr-facultyDetail"></hr>
        {redirect == true && <Redirect message={message} />}
        <Col xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Faculty Member
              </Form.Label>
              <Form.Control
                disabled
                className="activity_form_control"
                name="lastName"
                defaultValue={props.lastName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Termination Date *
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                type="date"
                name="TermDate"
                required
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Previous Title Held
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="PreviousTitle"
                maxLength="100"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Notes
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                as="textarea"
                rows={3}
                maxLength="100"
                name="Notes"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                VF
              </Form.Label>
              <Col style={{ marginLeft: "-10px" }}>
                <Form.Check
                  className="activity_form_control"
                  type="checkbox"
                  name="Vf"
                  onChange={handleCheck}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                VF Apt Date
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                type="date"
                name="VfaptDate"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Col style={{ marginLeft: "inherit" }}>
                <Button className="activity_form_label" type="submit" ref={btnRef}>
                  Create
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default Termination;
