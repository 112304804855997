import React, { useState, useEffect, useContext } from "react";
import { DetailContext } from "../../context/DetailContext";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { createFilterOptions, Link, Alert } from "@material-ui/core";
import UserForm from "../CreateNew/UserForm";
import { homeApi } from "../api";
import Spinner from "../../spinner";
import "../styles.css";
import { useOktaAuth } from "@okta/okta-react";
import { appInsights } from "../../appInsights";

const EditFaculty = () => {
  // const [dropdownList, setdropdownList] = useState([]);
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  const { detailList, userList, id } = useContext(DetailContext);

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth
        .getUser()
        .then((info) => {
          setUserInfo(info.name);
        })
        .catch();
    }
  });

  const EditNewUser = async (user) => {
    let formData = new FormData();

    Object.entries(user).map((entry) => {
      const key = entry[0];
      const value = entry[1];
      if (value !== null) {
        if (key.toLowerCase() === "affinityGroupId".toLowerCase() || key.toLowerCase() === "ethnicityIds".toLowerCase()) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
        if (key.toLowerCase() === "imageRaw") {
          value.files.map((file) => {
            formData.append(key, file);
          });
        }
      }
    });
    formData.append("createdBy", userInfo);

    formData.append("modifiedBy", userInfo);

    homeApi
      .putfacultyDetails(id, formData, authState.accessToken.value)
      .then((response) => {
        setRedirect({ message: "success" });
        setTimeout(() => {
          setRedirect({ redirect: true });
        }, 3000);
      })
      .catch((err) => {
        console.error(err);
        appInsights.trackException({ error: err });
        if (err.response.data.message) {
          setRedirect({ redirect: false, message: err.response.data.message });
        } else {
          setRedirect({ redirect: false, message: "error" });
        }
      });
  };

  if (userList === undefined || Object.keys(userList).length === 0) {
    return <Spinner />;
  } else if (Object.keys(userList).length === 0) {
    <Alert severity="error">No faculty found.</Alert>;
  } else {
    if (redirect.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { message: redirect.message },
          }}
        />
      );
    } else {
      return (
        <>
          <div className="col-lg-4 col-md-5">
            {redirect.message === "success" ? (
              <>
                {window.scrollTo(0, 0)}
                <Alert severity="success">
                  Form is successfully submitted,please wait while we are
                  redirecting!
                </Alert>
              </>
            ) : redirect.message === "error" ? (
              <>
                {window.scrollTo(0, 0)}
                <Alert severity="error" style={{ color: "#EA1D36" }}>
                  Error in submitting the form, please try again!
                </Alert>
              </>
            ) : redirect.message ? (
              <>
                {window.scrollTo(0, 0)}
                <Alert severity="error" style={{ color: "#EA1D36" }}>
                  {redirect.message}
                </Alert>
              </>
            ) : (
              <></>
            )}
            <UserForm
              onSubmit={EditNewUser}
              userList={userList}
              detailList={detailList}
              id={id}
              formData={detailList.faculty}
            />
          </div>
        </>
      );
    }
  }
};

export default EditFaculty;
