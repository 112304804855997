import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
function Redirect(props) {
  return (
    <div>
      {props.message == "200" && (
        <>
          <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" style={{ color: "#4DAA50" }}>
              Success!
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  color: "#000000",
                }}
                id="alert-dialog-description"
              >
                Successfully saved!Please wait redirecting...
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      )}
      {props.detailMessage && (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: "#EA1D36" }}>
            Failed !
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{
                fontSize: "20px",
                color: "#000000",
              }}
              id="alert-dialog-description"
            >
              {props.detailMessage}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
      {props.message == "400" && (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: "#EA1D36" }}>
            Failed !
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              style={{
                fontSize: "20px",
                color: "#000000",
              }}
              id="alert-dialog-description"
            >
              Something went wrong!!please retry...
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default Redirect;
