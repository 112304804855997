import React, { useState, useEffect } from "react";
import Spinner from "../../../spinner";
import { useLocation } from "react-router-dom";
import { homeApi } from "../../api";
import DetailDisplayList from "./DetailDisplayList";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/core";
import "../../styles.css";
import { useOktaAuth } from "@okta/okta-react";
import { appInsights } from "../../../appInsights";
import { convertCamelCase } from "../../../helpers/CaseHelper";

const Detail = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [detailList, setDetailList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  if (props.location.state === undefined) {
    setTimeout(() => {
      window.location.href = "/";
    }, 150);
  }
  let tableName;
  let faculty = props.location.state.detailList;
  let facultyMember = `${faculty.faculty.lastName}, ${faculty.faculty.firstName}`;
  let dataTable = props.location.state.dataTable;
  let tableid = props.location.tableid;

  // const convertCamelCase = (value) => {
  //   var result = value.replace(/([A-Z])/g, " $1");
  //   var changedValue = result.charAt(0).toUpperCase() + result.slice(1);
  //   return changedValue;
  // };

  if (props.match.params.type === "ranks") {
    tableName = "ranklogs";
  } else if (props.match.params.type === "previousEmployment") {
    tableName = "preemployment";
  } else if (props.match.params.type === "fellowships") {
    tableName = "fellowships";
  } else if (props.match.params.type === "medicalSchool") {
    tableName = "medicalschools";
  } else if (props.match.params.type === "adminTitles") {
    tableName = "admintitles";
  } else if (props.match.params.type === "facultyStatusChanges") {
    tableName = "facultystatuschanges";
  } else if (props.match.params.type === "promotions") {
    tableName = "promotions";
  } else if (props.match.params.type === "visas") {
    tableName = "visas";
  } else if (props.match.params.type === "residencies") {
    tableName = "residencies";
  } else if (props.match.params.type === "internships") {
    tableName = "internships";
  } else if (props.match.params.type === "terminations") {
    tableName = "terminations";
  } else if (props.match.params.type === "mentors") {
    tableName = "mentors";
  } else if (props.match.params.type === "cart") {
    tableName = "cart";
  } else if (props.match.params.type === "accomplishments") {
    tableName = "staffkeyinfo";
  }

  useEffect(() => {
    const fetchData = async () => {
      homeApi
        .getFacultyDetailTable(
          props.location.state.dataTable.id,
          tableName,
          authState.accessToken.value
        )
        .then((response) => {
          setDetailList(response);
          setRedirect(true);
          setMessage("200");
        })
        .catch((err) => {
          setRedirect(true);
          setMessage("400");
          console.error(err);
          appInsights.trackException({ error: err });
          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
        });
    };
    fetchData();
  }, []);

  if (detailList === undefined || Object.keys(detailList).length === 0) {
    return <Spinner />;
  } else {
    return (
      <React.Fragment>
        <div className="container">
          {props.match.params.type === "accomplishments" ? (
            <h3 className="heading-displayTable">
              {" "}
              Key Info for OOAA Staff Details
            </h3>
          ) : 
          props.match.params.type === "medicalSchool" ? (
            <h3 className="heading-displayTable">
              {" "}
              Medical School/Graduate Program Details
            </h3>
          ) : 
          (
            <h3 className="heading-displayTable">{`${convertCamelCase(
              props.match.params.type
            )} Details`}</h3>
          )}

          <span className="link-style span-link-btn">
            <Link
              className="new"
              to={{
                pathname: `/detail/${props.location.state.detailList.faculty.id}`,
              }}
            >
              Back
            </Link>
          </span>
          <hr className="hr-displayTable"></hr>
          {detailList !== null && Object.keys(detailList).length === 0 ? (
            <div
              style={{
                marginBottom: "20%",
                marginTop: "10px",
                marginLeft: "10%",
                marginRight: "10%",
              }}
            >
              <Alert severity="info">
                {`No ${props.match.params.type} found for this user.`}
              </Alert>
            </div>
          ) : (
            detailList.map((field, index) => {
              let value = field;
              return (
                <DetailDisplayList
                  key={index}
                  value={value}
                  facultyMember={facultyMember}
                  tableName={tableName}
                />
              );
            })
          )}
        </div>
      </React.Fragment>
    );
  }
};

export default Detail;
