import React, { useState, useEffect, useContext } from "react";
import { Link } from "@material-ui/core";
import { Table } from "react-bootstrap";
import FacultyDisplayTable from "./FacultyDisplayTable";
import { DetailContext } from "../../context/DetailContext";
import "../styles.css";

const FacultyDetails = () => {
  const { detailList, id } = useContext(DetailContext);
  return (
    <div className="col-lg-8 col-md-7">
      {Object.entries(detailList).map((field, index) => {
        const key = field[0];
        const value = field[1];
        if (Array.isArray(value)) {
          return (
            <FacultyDisplayTable
              tableName={key}
              tableData={value}
              key={index}
              id={id}
            />
          );
        }
      })}
    </div>
  );
};

export default FacultyDetails;
