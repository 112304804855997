import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import FacultyInfo from "./DisplayFaculty";
import EditFaculty from "./EditFaculty";
import FacultyDetails from "./FacultyDetails";
import { Link, Alert } from "@material-ui/core";
import { homeApi } from "../api";
import Spinner from "../../spinner";
import { DetailContext } from "../../context/DetailContext";
import { Redirect } from "react-router-dom";
import {
  GENDERS,
  ETHNICITIES,
  DEPARTMENTS,
  EMPLOYMENTTYPES,
  DIVISIONS,
  FACULTYTYPES,
  AFFINITYGROUPS,
  FISCALYEARS,
} from "../../config";

import "../styles.css";
import { useOktaAuth } from "@okta/okta-react";
import { appInsights } from "../../appInsights";
import TerminateFacultyModal from "./TerminateFacultyModal";
import RejoinFacultyModal from "./RejoinFacultyModal";

const FacultyMemberDetail = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  let show = false;
  const { id } = useParams();
  // let id = props.location.state.detail.id;
  let path = props.location.pathname;
  if (path.includes("edit")) {
    show = true;
  }
  const [loading, setLoading] = useState(false);
  const [detailList, setDetailList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      homeApi
        .getFacultyDetail(id, authState.accessToken.value)
        .then((response) => {
          setDetailList(response);
          setRedirect(true);
          setMessage("200");
        })
        .catch((err) => {
          console.error(err);
          appInsights.trackException({ error: err });
          setRedirect(true);
          setMessage("400");
          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
        });

      homeApi
        .postDropDownFormDataList(
          [
            GENDERS,
            ETHNICITIES,
            DEPARTMENTS,
            EMPLOYMENTTYPES,
            DIVISIONS,
            FACULTYTYPES,
            AFFINITYGROUPS,
            FISCALYEARS,
          ],
          authState.accessToken.value
        )
        .then((response) => {
          setUserList(response);
        })
        .catch((err) => {
          appInsights.trackException({ error: err });
          console.error(err);
        });
    };
    fetchData();
  }, []);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleConfirmTerminate = (data) => {
    // TODO: Add API call to terminate faculty
    handleModalClose();
    setLoading(true);
    homeApi
      .postTerminateFaculty(data, authState.accessToken.value)
      .then((response) => {
        setRedirect({
          message: "success",
        });
        setTimeout(() =>{ 
          window.location.reload();
          setRedirect({ redirect: true })
        }
          , 1500);
      })
      .catch((e) => {
        appInsights.trackException({ error: e });
        console.error(e);
        setLoading(false);
        setRedirect({
          redirect: false,
          message: e.response.data,
        });
      });
  };

  const handleConfirmRejoin = (data) => {
    // TODO: Add API call to rejoin faculty
    handleModalClose();
    setLoading(true);
    homeApi
      .postRejoinFaculty(data, authState.accessToken.value)
      .then((response) => {
        setRedirect({
          message: "success",
        });
        setTimeout(() => {
          window.location.reload();
          setRedirect({ redirect: true })}, 
        1500);
      })
      .catch((e) => {
        appInsights.trackException({ error: e });
        console.error(e);
        setLoading(false);
        setRedirect({
          redirect: false,
          message: e.response?.data,
        });
      });
  };

  if (detailList === undefined || Object.keys(detailList).length === 0) {
    return <Spinner />;
  } else if (Object.keys(detailList).length === 0) {
    <Alert severity="error">No faculty found.</Alert>;
  } else {
    if (redirect.redirect) {
      return (
        <Redirect
          to={{
            pathname: `/`,
            state: { message: redirect.message },
          }}
        />
      );
    } else
      return (
        <React.Fragment>
          <div className="container">
            <h2 className="heading-facultyDetail">{`${detailList.faculty.lastName}, ${detailList.faculty.firstName}`}</h2>
            {show ? (
              <span className="span-link-btn">
                <Link className="new" href={`/detail/${id}`}>
                  Detail
                </Link>
                <span className="span-sp">|</span>
                <Link className="new" href="/">
                  Back to List
                </Link>
              </span>
            ) : (
              <span className="span-link-btn">
                {/* <Link className="new" onClick={() => handleModalOpen()}>
                  Rejoin
                </Link> */}
                {detailList.faculty.isTerminated ? (
                  <Link className="new" onClick={() => handleModalOpen()}>
                    Rejoin
                  </Link>
                ) : (
                  <Link className="new" onClick={() => handleModalOpen()}>
                    Terminate
                  </Link>
                )}
                <span className="span-sp">|</span>
                <Link className="new" href={`/edit/${id}`}>
                  Edit
                </Link>
                <span className="span-sp">|</span>
                <Link className="new" href="/">
                  Back to List
                </Link>
              </span>
            )}
            <hr className="hr-facultyDetail"></hr>
            {redirect.message === "success" ? (
              <>
                {window.scrollTo(0, 0)}
                <Alert className="mb-2 mt-3" severity="success">
                  Form is successfully submitted,please wait while we are
                  redirecting!
                </Alert>
              </>
            ) : redirect.message === "error" ? (
              <>
                {window.scrollTo(0, 0)}
                <Alert
                  className="mb-2 mt-3"
                  severity="error"
                  style={{ color: "#EA1D36" }}
                >
                  Error in submitting the form, please try again!
                </Alert>
              </>
            ) : redirect.message ? (
              <>
                {window.scrollTo(0, 0)}
                <Alert
                  className="mb-2 mt-3"
                  severity="error"
                  style={{ color: "#EA1D36" }}
                >
                  {redirect.message}
                </Alert>
              </>
            ) : (
              <></>
            )}
            <div className="row">
              <DetailContext.Provider
                value={{ detailList, userList, id, setDetailList, setUserList }}
              >
                {show ? <EditFaculty /> : <FacultyInfo />}
                <FacultyDetails />
              </DetailContext.Provider>
            </div>
          </div>
          {/* <RejoinFacultyModal
            show={showModal}
            handleClose={handleModalClose}
            handleConfirm={(data) => handleConfirmRejoin(data)}
            facultyName={`${detailList.faculty.lastName}, ${detailList.faculty.firstName}`}
            facultyId={detailList.faculty.id}
          /> */}
          {detailList.faculty.isTerminated ? (
            <RejoinFacultyModal
              show={showModal}
              handleClose={handleModalClose}
              handleConfirm={(data) => handleConfirmRejoin(data)}
              facultyName={`${detailList.faculty.lastName}, ${detailList.faculty.firstName}`}
              facultyId={detailList.faculty.id}
            />
          ) : (
            <TerminateFacultyModal
              show={showModal}
              handleClose={handleModalClose}
              handleConfirm={(data) => handleConfirmTerminate(data)}
              facultyName={`${detailList.faculty.lastName}, ${detailList.faculty.firstName}`}
              facultyId={detailList.faculty.id}
              startDtm={detailList.faculty.uscHireDt}
            />
          )}
        </React.Fragment>
      );
  }
};

export default FacultyMemberDetail;
