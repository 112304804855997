import * as React from "react";
import { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Divider, Grid } from "@material-ui/core";
import { useOktaAuth } from "@okta/okta-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { homeApi } from "../api";
import { useLocation, Link } from "react-router-dom";
import "../../components/styles.css";
import { createTheme } from "@material-ui/core/styles";
import Spinner from "../../../src/spinner";
import { appInsights } from "../../appInsights";
const theme = createTheme({
  typography: {
    fontFamily: ['"Poppins"'].join(","),
  },
});
var username,
  activity = "";

export default function Edit(props) {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [dropdown, setDropdown] = useState([]);
  const [check, setCheck] = useState();
  const [changes, setChanges] = useState(false);
  const [message, setMessage] = useState(
    "Something went wrong please retry..."
  );
  const [data, setData] = useState({});
  const id = `${props.match.params.id}`;
  const type = `${props.match.params.type}`;
  const { authState, oktaAuth } = useOktaAuth();
  var url = type.concat(`/${id}`);
  let map1 = new Map([
    ["activitytype", "Activity Type"],
    ["administrativetitletype", "Admin Title Type"],
    ["affinitygroup", "Affinity Group"],
    ["departments", "Department"],
    ["designations", "Designation"],
    ["divisions", "Division"],
    ["employmenttype", "Employment Type"],
    ["ethnicities", "Ethnicity"],
    ["facultytypes", "Faculty Type"],
    ["ranks", "Rank"],
    ["employmentstatus", "Status"],
    ["tenures", "Tenure"],
    ["tracks", "Track"],
    ["fiscalyears", "Fiscal Years"],
    ["genders", "Gender"],
  ]);
  let map2 = new Map([
    ["activitytype", "activity"],
    ["administrativetitletype", "type"],
    ["affinitygroup", "title"],
    ["departments", "primaryDepartment"],
    ["designations", "title"],
    ["divisions", "title"],
    ["employmenttype", "type"],
    ["ethnicities", "title"],
    ["facultytypes", "type"],
    ["ranks", "title"],
    ["employmentstatus", "status"],
    ["tenures", "title"],
    ["tracks", "title"],
    ["fiscalyears", "period"],
    ["genders", "title"],
  ]);

  const headerprops = map2.get(type);
  const headertitle = map1.get(type);
  activity = `${location.state.data[headerprops]}`;

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth
        .getUser()
        .then((info) => {
          username = info.name;
        })
        .catch();
    }
    setData(location.state.data);
    setCheck(location.state.data.active);
  }, []);
  const handleCheck = () => {
    setChanges(true);
    setCheck(!check);
  };

  const handleChange = ({ target: { name, value } }) => {
    setChanges(true);
    activity = value;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    const postBody = {
      ...data,
      active: check,
      modifiedBy: username,
      id: id,
      modifiedOn: new Date().toISOString(),
      createdOn: new Date().toISOString(),
    };
    e.preventDefault();
    if (changes === true) {
      homeApi
        .putDropdownDetails(url, postBody, authState.accessToken.value)
        .then((res) => {
          setData(res);
          setFeedback("200");
          setOpen(true);
          setTimeout(() => {
            window.location.href = `/${props.match.params.type}`;
          }, 1500);
        })
        .catch((e) => {
          appInsights.trackException({ error: e });
          console.error(e);
          setFeedback("404");
          setMessage(e.response.data.message);
          setOpen(true);
        });
    } else {
      setTimeout(() => {
        window.location.href = `/${props.match.params.type}`;
      }, 1500);
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail">Edit {headertitle}</h2>
        <span className="span-link-btn-dropdown">
          <Link className="new" to={`/${props.match.params.type}`}>
            Back to list
          </Link>
        </span>

        <hr className="hr-facultyDetail"></hr>

        <Grid style={{ marginTop: "5%", marginLeft: "15%" }} item xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Col lg={6}>
                <Form.Label style={{ width: "100%" }} column>
                  {headertitle} *
                </Form.Label>
              </Col>

              <Col lg={6}>
                <Form.Control
                  style={{ width: "100%" }}
                  name={headerprops}
                  onChange={handleChange}
                  disabled={headerprops === "period" ? "disabled" : ""}
                  type="text"
                  required
                  maxLength="100"
                  defaultValue={location.state.data[headerprops]}
                ></Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formHorizontalCheck">
              <Col>
                <Form.Label column>Active</Form.Label>
              </Col>

              <Col>
                <Form.Check
                  style={{ marginLeft: "24px", padding: "7px 0px" }}
                  defaultChecked={location.state.data.active}
                  onChange={handleCheck}
                  name="active"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col lg={6}>
                <Button style={{ marginLeft: "-1px" }} type="submit">
                  Save
                </Button>
              </Col>
              {feedback == "200" && (
                <>
                  <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle
                      theme={theme}
                      id="alert-dialog-title"
                      style={{ color: "#4DAA50" }}
                    >
                      Success!
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        style={{
                          fontSize: "20px",

                          color: "#000000",
                        }}
                        theme={theme}
                        id="alert-dialog-description"
                      >
                        Successfully saved! Please wait redirecting...
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                </>
              )}
              {feedback == "404" && (
                <Dialog
                  open={open}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ color: "#EA1D36" }}
                    theme={theme}
                  >
                    Failed to edit!
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      style={{
                        fontSize: "20px",

                        color: "#000000",
                      }}
                      theme={theme}
                      id="alert-dialog-description"
                    >
                      {message} !! Please retry...
                    </DialogContentText>
                    <Button onClick={() => setOpen(false)}>OK</Button>
                  </DialogContent>
                </Dialog>
              )}
            </Form.Group>
          </Form>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
