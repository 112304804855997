import React from "react";
import CreateNewTable from "./createNewTable";
import { useParams } from "react-router-dom";
function CreateNewFormTable({ match }) {
  let type = `${match.params.type}`;
  const { id } = useParams();
  return <CreateNewTable type={type} id={id} linkType="create" />;
}

export default CreateNewFormTable;
