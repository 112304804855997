import react, { useState, useEffect } from "react";
import "../styles.css";
import UserForm from "./UserForm";
import { homeApi } from "../api";
import { createFilterOptions, Link, Alert } from "@material-ui/core";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import {
  GENDERS,
  ETHNICITIES,
  DEPARTMENTS,
  EMPLOYMENTTYPES,
  DIVISIONS,
  FACULTYTYPES,
  AFFINITYGROUPS,
} from "../../config";
import { useOktaAuth } from "@okta/okta-react";
import { appInsights } from "../../appInsights";
import Spinner from "../../spinner";

const CreateNewFaculty = () => {
  const [userList, setUserList] = useState([]);
  const [redirect, setRedirect] = useState({ redirect: false, message: "" });
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  const FORM_DATA = {
    lastName: "",
    firstName: "",
    middleInitial: "",
    email: "",
    suffix: "",
    usc10id: "",
    employeeId: null,
    dob: "",
    ssn: "",
    location: "",
    contactNumber: "",
    primaryDeptStartDt: "",
    divisionId: null,
    facultyTypeId: null,
    primaryDeptId: null,
    ethnicityIds: null,
    genderId: null,
    employmentTypeId: null,
    primaryDeptEndDt: "",
    uscHireDt: "",
    secondaryDept: "",
    officiallyAppointed: "",
    visiting: false,
    percent: "",
    lastPromotion: "",
    physScientist: false,
    active: false,
    researchInterests: "",
    comments: "",
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth
        .getUser()
        .then((info) => {
          setUserInfo(info.name);
        })
        .catch();
    }
    homeApi
      .postDropDownFormDataList(
        [
          GENDERS,
          ETHNICITIES,
          DEPARTMENTS,
          EMPLOYMENTTYPES,
          DIVISIONS,
          FACULTYTYPES,
          AFFINITYGROUPS,
        ],
        authState.accessToken.value
      )
      .then((response) => {
        setUserList(response);
      })
      .catch((err) => {
        appInsights.trackException({ error: err });
        console.log(err);
      });
  }, []);

  const createNewUser = async (user) => {
    setLoading(true);
    let formData = new FormData();
    Object.entries(user).map((entry) => {
      const key = entry[0];
      const value = entry[1];
      if (value !== null) {
        if (key.toLowerCase() === "affinityGroupId".toLowerCase() || key.toLowerCase() === "ethnicityIds".toLowerCase()) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
        if (key.toLowerCase() === "imageRaw") {
          value.files.map((file) => {
            formData.append(key, file);
          });
        }
      }
    });
    formData.append("createdBy", userInfo);
    // formData.append("modifiedBy", userInfo);

    homeApi
      .createNewUser(formData, authState.accessToken.value)
      .then((response) => {
        setLoading(false);
        setRedirect({ message: "success" });
        setTimeout(() => {
          setRedirect({ redirect: true });
        }, 3000);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        appInsights.trackException({ error: err });
        if (err.response.data.message) {
          setRedirect({ redirect: false, message: err.response.data.message });
        } else {
          setRedirect({ redirect: false, message: "error" });
        }
      });
  };

  if(loading === true){
    return (
      <div className="col loader mb-3">
      <Spinner />
    </div>
    )
  }
  else if (userList === undefined || Object.keys(userList).length === 0) {
    return <Spinner />;
  }
   else {
    if (redirect.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { message: redirect.message },
          }}
        />
      );
    } else {
      return (
        <div className="container">
          {redirect.message === "success" ? (
            <>
              {window.scrollTo(0, 0)}
              <Alert severity="success">
                Form is successfully submitted,please wait while we are
                redirecting!
              </Alert>
            </>
          ) : redirect.message === "error" ? (
            <>
              {window.scrollTo(0, 0)}
              <Alert severity="error" style={{ color: "#EA1D36" }}>
                Error in submitting the form, please try again!
              </Alert>
            </>
          ) : redirect.message ? (
            <>
              {window.scrollTo(0, 0)}
              <Alert severity="error" style={{ color: "#EA1D36" }}>
                {redirect.message}
              </Alert>
            </>
          ) : (
            <></>
          )}
          <h2 className="heading-createNew">Add New Faculty Form</h2>
          <span className="span-link-btn">
            <Link className="new" href="/">
              Back to List
            </Link>
          </span>
          <hr className="hr-createNew" />
          <UserForm
            onSubmit={createNewUser}
            userList={userList}
            formData={FORM_DATA}
          />
        </div>
      );
    }
  }
};

export default CreateNewFaculty;
