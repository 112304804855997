import React from "react";
import { configuration } from "../config.js";
import FacultyMember from "./FacultyMember/FacultyMember";
import { homeApi } from "./api";
// import { css } from "@emotion/react";
import { Button, Spinner } from "react-bootstrap";
// import { BeatLoader } from "react-spinners";
import "./styles.css";
import { withOktaAuth } from "@okta/okta-react";
import { appInsights } from "../appInsights.js";
// const override = css`
//   display: block;
//   margin: 0 auto;
//   border-color: red;
// `;
const DOMAIN = configuration;
const NOT_AVAILABLE = "Not available";
console.log(appInsights)

class Faculty extends React.Component {
  state = {
    searchedName: "",
    facultyDetails: [],
    error: false,
    isLoaded: true,
  };

  // GET request fetching searched faculty members
  getFacultyMemberDetails = async (searchedName) => {
    this.setState({ isLoaded: false, error: false });
    homeApi
      .getFacultyMemberDetails(
        searchedName,
        this.props.authState.accessToken.value
      )
      .then(
        (result) => {
          if (searchedName !== "") {
            this.setState({
              facultyDetails: result,
              error: false,
              isLoaded: true,
            });
          }
        },
        (error) => {
          appInsights.trackException({ error: error });
          this.setState({ error: true, isLoaded: false });
        }
      );
  };

  // GET request all faculty
  getAllFaculty = async () => {
    this.setState({ isLoaded: false, error: false });
    homeApi.getAllFacultyDetails(this.props.authState.accessToken.value).then(
      (result) => {
        this.setState({
          facultyDetails: result,
          error: false,
          isLoaded: true,
        });
      },
      (error) => {
        appInsights.trackException({ error: error });
        this.setState({ error: true, isLoaded: false });
      }
    );
  };

  // handling keyup in text field

  handleChange = (e, target) => {
    this.setState({ error: false });
    if (e.keyCode === 13) {
      e.preventDefault();
      this.onClick();
    }
    if (e.target.value === "") {
      return this.setState({ error: true, facultyDetails: [] });
    }
    this.setState({ searchedName: e.target.value });
  };

  // on click on search button calling the API and passing the faculty name
  onClick = () => {
    if (this.refs.text.value === null || this.refs.text.value === "") {
      return this.setState({ error: true, facultyDetails: [] });
    }
    if (this.refs.text.value !== "") {
      this.getFacultyMemberDetails(this.state.searchedName);
    }
  };

  allFaculty = () => {
    this.refs.text.value = "";
    this.getAllFaculty();
  };

  validateInput = (input) => {
    if (input === null || input === "") {
      return NOT_AVAILABLE;
    } else {
      return input;
    }
  };

  render() {
    let error, result;
    error = <div className="error">Please enter the valid member name</div>;

    if (!this.state.isLoaded) {
      result = (
        <div
          className="beat-loader"
          style={{
            textAlign: "center",
            marginTop: "10%",
            marginBottom: "10%",
          }}
        >
        <Spinner
        as="span"
        variant="info"
        role="status"
        aria-hidden="true"
        color={"#00B4E8"}
        animation="border"/>
        </div>
      );
    } else if (this.state.facultyDetails.length > 0) {
      result = (
        <FacultyMember
          facultyDetails={this.state.facultyDetails}
          validateInput={this.validateInput}
        />
      );
    }

    return (
      <>
        <div
          className={`search-container ${
            this.state.facultyDetails.length <= 0 ? "setHeight" : ""
          } `}
        >
          <div className="heading">
            <h2 className="pt-5">Faculty Members</h2>
          </div>
          <div className="search-bar pt-5">
            <input
              className="col-md-8 col-12 search"
              type="text"
              placeholder="Search by first name and last name"
              onKeyUp={this.handleChange}
              ref="text"
            />
            <Button aria-controls="btn_search_drop" onClick={this.onClick}>
              Search
            </Button>
            <Button
              id="show-all-faculty"
              type="submit"
              onClick={this.allFaculty}
            >
              Show All Faculty
            </Button>
          </div>
          {this.state.error ? error : <>{result}</>}
        </div>
      </>
    );
  }
}
export default withOktaAuth(Faculty);
