import React, { useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Form,
  Row,
  Col,
  Button,
  Alert,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { homeApi } from "../../api";
import Redirect from "./Redirect";
import SliderCustom from "../../DetailFaculty/CreateNewTablesData/Slider";
import { useOktaAuth } from "@okta/okta-react";
import { appInsights } from "../../../appInsights";
import Spinner from "../../../spinner";

const CLINICAL = "Clinical";
const ADMINISTRATION = "Administration";
const RESEARCH = "Research";
const TEACHING = "Teaching";
const label = [CLINICAL, ADMINISTRATION, RESEARCH, TEACHING];
function Cart(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState(
    new Array(label.length).fill(Number(100 / label.length))
  );
  const [fiscalYear, setFiscalYear] = useState("");
  const [detail, setDetail] = useState("");

  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  function handleFiscalYearChange({ target: { index, name, value } }) {
    if (value != "Please make a selection" && name === "FiscalYearsId") {
      props.dropdown.map((value1, i) => {
        console.log(value)
        if (value == value1.period) {
          setFiscalYear(value1.id);
        }
      });
    }
    
  }

  function handleChange(index, value, name) {
    setErrorAlert(false);
    if (isNaN(value)) {
      value = 0;
    }
    setData((vs) =>
      vs.map((v, i) => {
        if (i === index) return parseFloat(value);
        return parseFloat(v);
      })
    );
  }

  function handleDetailChange({ target: { index, name, value } }) {
    setDetail({
      [name]: value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let arrSum = 0;
    for (let i = 0; i < data.length; i++) {
      arrSum += Number(data[i]);
    }

    if (arrSum !== 100) {
      setLoading(false);
      setErrorAlert(true);
    } else {
      const postBody = {
        clinicalPercentage: parseFloat(data[0]),
        administrationPercentage: parseFloat(data[1]),
        researchPercentage: parseFloat(data[2]),
        teachingPercentage: parseFloat(data[3]),
        FacultyId: Number(props.id),
        createdBy: props.createdBy,
        fiscalYearId: fiscalYear,
        ...detail,
      };
      homeApi
        .postCreateNewFacultyTable(
          "cart",
          postBody,
          authState.accessToken.value
        )
        .then((res) => {
          setLoading(false);
          setRedirect(true);
          setMessage("200");
          setTimeout(() => {
            window.location.href = `/detail/${Number(props.id)}`;
          }, 1500);
        })
        .catch((e) => {
          setLoading(false);
          setRedirect(true);
          setMessage("400");
          console.error(e);
          appInsights.trackException({ error: e });
          setTimeout(() => {
            window.location.href = `/detail/${Number(props.id)}`;
          }, 1500);
        });
    }
  };
  if (loading === true) {
    return (
      <div className="col loader mb-3">
        <Spinner />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <Container maxWidth="lg">
          <h2 className="heading-facultyDetail">Add New Cart</h2>
          <span className="span-link-btn">
            {" "}
            <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
              Back
            </Link>
          </span>
          <hr className="hr-facultyDetail"></hr>
          {redirect == true && <Redirect message={message} />}
          {errorAlert === true ? (
            <Alert variant="danger">All fields need to add up to 100%.</Alert>
          ) : (
            <></>
          )}
          <Col xs={6}>
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Row}>
                <Form.Label column>Fiscal Years *</Form.Label>
                <Form.Control
                  className="activity_form_control"
                  style={{ backgroundColor: "white", appearance: "menulist" }}
                  as="select"
                  name="FiscalYearsId"
                  onChange={handleFiscalYearChange}
                  required
                >
                <option value="">Please make a selection</option>
                  {props.dropdown.map((value, i) => (
                    <option key={i} value={value.fiscalYears}>
                      {value.period}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column>Notes</Form.Label>
                <Form.Control
                  maxLength="100"
                  name="Notes"
                  onChange={handleDetailChange}
                />
              </Form.Group>

              {label.map((item, index) => (
                <SliderCustom
                  key={index}
                  index={index}
                  value={data[index]}
                  title={item}
                  name={item}
                  onChange={(e) => handleChange(index, e.target.value)}
                />
              ))}

              <Form.Group as={Row}>
                <Col style={{ marginLeft: "inherit" }}>
                  <Button type="submit">Create</Button>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Container>
      </React.Fragment>
    );
  }
}

export default Cart;
