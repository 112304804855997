import React from "react";
import "../../styles.css";
import { convertCamelCase } from "../../../helpers/CaseHelper";

const DetailDisplayList = ({ value, facultyMember, tableName }) => {
  // const convertCamelCase = (value) => {
  //   var result = value.replace(/([A-Z])/g, " $1");
  //   var changedValue = result.charAt(0).toUpperCase() + result.slice(1);
  //   return changedValue;
  // };
  return (
    <div className="col-lg-8 col-md-8">
      <dl className="dl-horizontal">
        <dt>Faculty Member</dt>
        <dd>{facultyMember}</dd>
        {Object.entries(value).map((field, index) => {
          const key = field[0];
          const value = field[1];
          return (
            <>
              {key.toLowerCase().includes("id") ? (
                <>
                  <dt></dt>
                  <dd></dd>
                </>
              ) : (
                <>
                  {key === "period" ? (
                    <dt>Fiscal Year</dt>
                  ) :
                  key === "medicalSchoolName" ? (
                    <dt>School Name</dt>
                  ) :
                  key === "academicRank" ? (
                    <dt>Academic Rank/Title</dt>
                  ) :
                   (
                    <dt>{convertCamelCase(key)}</dt>
                  )}
                  {typeof value === "boolean" ? (
                    <dd className="displayFacultyList">
                      <input
                        checked={value}
                        className="check-box"
                        disabled="disabled"
                        type="checkbox"
                      />
                    </dd>
                  ) : { value } !== "null" ? (
                    <dd
                      id={`${tableName === "cart" ? "cart" : ""}`}
                      key={index}
                    >
                      {value}
                    </dd>
                  ) : (
                    <dd key={index}>""</dd>
                  )}
                </>
              )}
            </>
          );
        })}
      </dl>
    </div>
  );
};

export default DetailDisplayList;
