/**
 * @module api
 */

/** ************************************* */
/* Keep these lists alphabetized! */
/** ************************************* */
import * as _homeApi from "./home";

export default {
  home: _homeApi,
};

export const homeApi = _homeApi;

