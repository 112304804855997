import React from "react";
import { useState, useEffect } from "react";
import Layout from "./Layout";
import { homeApi } from "../api";
import Spinner from "../../../src/spinner";
import { useOktaAuth } from "@okta/okta-react";
import {appInsights} from "../../appInsights";
function EmploymentTypes() {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      homeApi
        .getDropdownDetails("employmenttype", authState.accessToken.value)
        .then((res) => {
          setData(res);
        })
        .catch((e) => {
          appInsights.trackException({ error: e});
          console.error(e);
        });
    }
    fetchData();
  }, []);
  if (data === undefined || Object.keys(data).length === 0) {
    return <Spinner />;
  } else {
    return (
      <Layout
        headData="Employment Types"
        rowData={data}
        header="employmenttype"
        keyData={["type", "createdOn", "createdBy", "modifiedOn", "modifiedBy"]}
      />
    );
  }
}

export default EmploymentTypes;
