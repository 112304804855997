import React from "react";
import { Table, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
// import '../styles.css';
import "./facultyMember.css";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

class FacultyMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableRows: [],
    };
  }

  componentWillMount = async () => {
    if (this.props.facultyDetails) {
      this.setState({ tableRows: this.assembleData() });
    }
  };

  assembleData = () => {
    let faculty = this.props.facultyDetails.map((detail) => {
      return {
        lastName: detail.lastName,
        firstName: detail.firstName,
        mi: detail.middleInitial,
        title: detail.suffix,
        uscId: detail.usc10id,
        employeeId: detail.employeeId,
        location: detail.location,
        contactNumber: detail.contactNumber,
        startDate:
          detail.startDate !== null && detail.startDate.includes("T")
            ? detail.startDate.split("T")[0]
            : detail.startDate,
        email: (
          <>
            <a
              style={{ color: "#0d9ddb" }}
              target="_blank"
              href={`mailto: ${detail.email}`}
            >
              Send Email
            </a>
            <span style={{ color: "#337ab7" }}> | </span>{" "}
            <a
              style={{ color: "#0d9ddb" }}
              target="_blank"
              href={
                "https://teams.microsoft.com/l/chat/0/0?users=" + detail.email
              }
            >
              Chat
            </a>
          </>
        ),
        active: <Form.Check type="checkbox" disabled checked={detail.active} />,
        department: detail.department,
        division: detail.division,
        type: detail.facultyType,
        editDetail: (
          <>
            {" "}
            <Link
              className="link-btn-display"
              to={{ pathname: `/edit/${detail.id}`, state: { detail: detail } }}
            >
              Edit
            </Link>
            <span className="span-sp"></span>
            <Link
              className="link-btn-display"
              to={{
                pathname: `/detail/${detail.id}`,
                state: { detail: detail },
              }}
            >
              Details
            </Link>
          </>
        ),
      };
    });
    return faculty;
  };

  render() {
    const data = {
      columns: [
        {
          label: "Last Name",
          field: "lastName",
          sort: "asc",
          width: 150,
        },
        {
          label: "First Name",
          field: "firstName",
          sort: "asc",
          width: 150,
        },
        {
          label: "Title",
          field: "title",
          sort: "asc",
          width: 150,
        },
        {
          label: "USC ID",
          field: "uscId",
          sort: "asc",
          width: 100,
        },
        {
          label: "Employee ID",
          field: "employeeId",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Number",
          field: "contactNumber",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Contact",
          field: "email",
          sort: "asc",
          width: 150,
        },
        {
          label: "Department",
          field: "department",
          sort: "asc",
          width: 150,
        },
        {
          label: "Division",
          field: "division",
          sort: "asc",
          width: 150,
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
          width: 100,
        },
        {
          label: "Active",
          field: "active",
          sort: "disabled",
          width: 100,
        },
        {
          label: "",
          field: "editDetail",
          sort: "disabled",
          width: 150,
        },
      ],
      rows: this.state.tableRows,
    };
    return (
      <MDBDataTable
        responsive
        small
        data={data}
        id="responsive-table"
        searching={false}
      />
    );
  }
}

export default FacultyMember;
