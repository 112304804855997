import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Divider, Grid, Link } from "@material-ui/core";
import { homeApi } from "../api";
import { useOktaAuth } from "@okta/okta-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {appInsights} from "../../appInsights";

let username;
export default function CreateNewActivity(props) {
  let head = props.match.params.types;

  let map1 = new Map([
    ["activitytype", "Activity Type"],
    ["administrativetitletype", "Admin Title Type"],
    ["affinitygroup", "Affinity Group"],
    ["departments", "Department"],
    ["designations", "Designation"],
    ["divisions", "Division"],
    ["genders", "Gender"],
    ["employmenttype", "Employment Type"],
    ["ethnicities", "Ethnicity"],
    ["facultytypes", "Faculty Type"],
    ["ranks", "Rank"],
    ["employmentstatus", "Status"],
    ["tenures", "Tenure"],
    ["tracks", "Track"],
    ["fiscalyears", "Fiscal Years"],
  ]);
  let map2 = new Map([
    ["activitytype", "activity"],
    ["administrativetitletype", "type"],
    ["affinitygroup", "title"],
    ["departments", "primaryDepartment"],
    ["designations", "title"],
    ["divisions", "title"],
    ["genders", "title"],
    ["employmenttype", "type"],
    ["ethnicities", "title"],
    ["facultytypes", "type"],
    ["ranks", "title"],
    ["employmentstatus", "status"],
    ["tenures", "title"],
    ["tracks", "title"],
    ["fiscalyears", "period"],
  ]);
  const { authState, oktaAuth } = useOktaAuth();
  const headerprops = map2.get(head);
  const headertitle = map1.get(head);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [feedback, setFeedback] = useState(null);
  let btnRef = useRef();
  const [message, setMessage] = useState(
    "Something went wrong please retry..."
  );
  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth
        .getUser()
        .then((info) => {
          username = info.name;
        })
        .catch();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    var re = new RegExp(/^FY(\d{2})$/);
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "true");
    }
    const postBody = {
      ...data,
      createdBy: username,
      modifiedBy: username,
      active: true,
    };
    if (headertitle === "Fiscal Years" && !re.test(postBody.period)) {
      setError(true);
    } else {
      homeApi
        .postDropdownDetails(`${head}`, postBody, authState.accessToken.value)
        .then((res) => {
          setData(res);
          setFeedback("200");
          setOpen(true);
          setTimeout(() => {
            window.location.href = `/${props.match.params.types}`;
          }, 1500);
        })
        .catch((e) => {
          console.error(e);
          appInsights.trackException({ error: e});
          setFeedback("400");
          setMessage(e.response.data.message);
          setOpen(true);
        });
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setError(false);
    if (error === false) {
      btnRef.current.removeAttribute("disabled");
    }
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail">Create New {headertitle}</h2>
        <span className="span-link-btn-dropdown">
          <Link
            style={{
              color: "#004877",
              textDecoration: "none",
              fontWeight: "bold",
            }}
            href={`/${props.match.params.types}`}
          >
            Back to List
          </Link>
        </span>
        <hr className="hr-facultyDetail"></hr>

        <Grid style={{ marginTop: "5%", marginLeft: "15%" }} item xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Col lg={6}>
                <Form.Label column>{headertitle} *</Form.Label>
              </Col>

              <Col lg={6}>
                <Form.Control
                  style={{ width: "100%" }}
                  name={headerprops}
                  onChange={handleChange}
                  type="text"
                  required
                  maxLength="100"
                  placeholder={headertitle}
                />

                {headertitle === "Fiscal Years" ? (
                  <>
                    {error ? (
                      <>
                        {window.scrollTo(0, 0)}
                        <span className="text-danger">
                          Fiscal Year format should be FYYY
                        </span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <span style={{ fontSize: "12px", marginLeft: "2%" }}>
                          Fiscal Year format FYYY
                        </span>{" "}
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Col lg={6}>
                <Button
                  type="submit"
                  ref={btnRef}
                  style={{ marginLeft: "-1px" }}
                >
                  Create
                </Button>
              </Col>
              {feedback == "200" && (
                <Dialog
                  open={open}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ color: "#4DAA50"}}
                  >
                    Success!
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      style={{
                        fontSize: "20px",
                        color: "#000000",
                      }}
                      id="alert-dialog-description"
                    >
                      Successfully created! Please wait redirecting...
                    </DialogContentText>
                    {/* <Link href={`/${props.match.params.types}`}>
                      Back to List
                    </Link> */}
                  </DialogContent>
                </Dialog>
              )}
              {feedback == "400" && (
                <Dialog
                  open={open}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    style={{ color: "#EA1D36" }}
                  >
                    Failed to create!
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      style={{
                        fontSize: "20px",
                        color: "#000000",
                      }}
                      id="alert-dialog-description"
                    >
                      {message} !! Please retry...
                    </DialogContentText>
                    <Button onClick={() => setOpen(false)}>OK</Button>
                  </DialogContent>
                </Dialog>
              )}
            </Form.Group>
          </Form>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
