import React, { useState, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import { Form, Row, Col, Button } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { homeApi } from "../../api";
import Redirect from "./Redirect";
import { useOktaAuth } from "@okta/okta-react";
import { appInsights } from "../../../appInsights";

function AdminTitle(props) {
  const [data, setData] = useState({});
  const { authState, oktaAuth } = useOktaAuth();

  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const [textmessage, setTextMessage] = useState("");
  const [error, setError] = useState(false);

  let btnRef = useRef();
  const handleChange = ({ target: { name, value } }) => {
    setError(false);
    if (error === false) {
      btnRef.current.removeAttribute("disabled");
    }
    if (value != "Please make a selection" && name === "titleTypeId") {
      setData({
        ...data,
        [name]: Number(value),
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (btnRef.current) {
      btnRef.current.setAttribute("disabled", "true");
    }
    const postBody = {
      ...data,
      FacultyId: Number(props.id),
      createdBy: props.createdBy,
    };

    homeApi
      .postCreateNewFacultyTable(
        "admintitles",
        postBody,
        authState.accessToken.value
      )
      .then((res) => {
        setRedirect(true);
        setMessage("200");
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      })
      .catch((e) => {
        setRedirect(true);
        setMessage("400");
        console.error(e);
        appInsights.trackException({ error: e });
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      });
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail">Add New Admin Title</h2>
        <span className="span-link-btn">
          {" "}
          <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
            Back
          </Link>
        </span>
        <hr className="hr-facultyDetail"></hr>
        {redirect == true && <Redirect message={message} />}
        <Col xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label column>Faculty Member Last Name</Form.Label>
              <Form.Control
                disabled
                name="lastName"
                defaultValue={props.lastName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>New Title *</Form.Label>
              <Form.Control
                maxLength="60"
                name="Title"
                required
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column>
                Admin Title Type *
              </Form.Label>
              <Form.Control
                style={{ backgroundColor: "white", appearance: "menulist" }}
                as="select"
                required
                onChange={handleChange}
                name="titleTypeId"
              >
                <option value="">Please make a selection</option>
                {props.dropdownAdminTitleType.map((value, i) => (
                  <option key={i} value={value.id}>
                    {value.type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Start Date</Form.Label>
              <Form.Control
                name="startDate"
                type="date"
                // max={d
                //   data.endDate !== undefined && data.endDate !== null
                //     ? data.endDate.split("T")[0]
                //     : ""
                // }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>End Date</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                // min={
                //   data.startDate !== undefined && data.startDate !== null
                //     ? data.startDate.split("T")[0]
                //     : ""
                // }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column>Stipend</Form.Label>
              <Form.Control
                type="number"
                name="stipend"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Col style={{ marginLeft: "inherit" }}>
                <Button type="submit" ref={btnRef}>
                  Create
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default AdminTitle;
