import React from "react";
import "../../styles.css";
import { makeStyles } from "@material-ui/styles";
import { Slider } from "@material-ui/core";
import {
  Form,Row
} from "react-bootstrap";

const useStyles = makeStyles({
  // root: {
  //   width: "50%",
  //   color: "rgb(9,157,219)",
  //   padding: "0px",
  // },
});

const SliderCustom = ({ title, value, onChange, defaultValue }) => {
  const classes = useStyles();
  return (
    <>
      <div className="slider-container">

      <Form.Group as={Row}>
      <Form.Label column>{title}</Form.Label>
      <Form.Control
        maxLength="100"
        className={`${
          title === "Administration" ? "range-admin" : "range-margin"
        } ${classes.root}`}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        type="number"
      />
    </Form.Group>
       
      </div>
    </>
  );
};

export default SliderCustom;
