import { Modal, Button } from "react-bootstrap";

const ConfirmEditTerminationModal = ({
  show,
  handleClose,
  handleConfirm,
  facultyId,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        Termination data for Faculty ID <b>{facultyId}</b> changed. This might{" "}
        <b>affect</b> Rejoin and Terminate functionality.
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleConfirm} variant="danger">
          Proceed
        </Button>
        <Button variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmEditTerminationModal;
