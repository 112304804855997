import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CreateNewTable from "./createNewTable";

function EditNewTable() {
  let location = useLocation();
  const pathname = location.pathname;
  // const { id } = useParams();
  if (location.state === undefined) {
    setTimeout(() => {
      window.location.href = "/";
    }, 150);
  }
  const id = Number(location.state.id);
  return (
    <>
      {pathname.includes("facultyStatusChanges") && (
        <CreateNewTable
          linkType="edit"
          type="facultyStatusChanges"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("visas") && (
        <CreateNewTable
          linkType="edit"
          type="visas"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("adminTitles") && (
        <CreateNewTable
          linkType="edit"
          type="admintitles"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("ranks") && (
        <CreateNewTable
          linkType="edit"
          type="ranklogs"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("promotions") && (
        <CreateNewTable
          linkType="edit"
          type="promotions"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("mentors") && (
        <CreateNewTable
          linkType="edit"
          type="mentors"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("fellowships") && (
        <CreateNewTable
          linkType="edit"
          type="fellowships"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("internships") && (
        <CreateNewTable
          linkType="edit"
          type="internships"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("previousEmployment") && (
        <CreateNewTable
          linkType="edit"
          type="preemployment"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("residencies") && (
        <CreateNewTable
          linkType="edit"
          type="residencies"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("medicalSchool") && (
        <CreateNewTable
          linkType="edit"
          type="medicalschools"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("terminations") && (
        <CreateNewTable
          linkType="edit"
          type="terminations"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("cart") && (
        <CreateNewTable
          linkType="edit"
          type="cart"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
      {pathname.includes("accomplishments") && (
        <CreateNewTable
          linkType="edit"
          type="accomplishments"
          id={id}
          dataTable={location.state.dataTable}
          tableid={location.state.dataTable.id}
        />
      )}
    </>
  );
}

export default EditNewTable;
