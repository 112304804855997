import React from "react";
import { useState, useEffect } from "react";
import Layout from "./Layout";
import { homeApi } from "../api";
import Spinner from "../../../src/spinner";
import { useOktaAuth } from "@okta/okta-react";
import {appInsights} from '../../appInsights';

function ActivityTypes() {
  const [data, setData] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    async function fetchData() {
      homeApi
        .getDropdownDetails("activitytype", authState.accessToken.value)
        .then((res) => {
          setData(res);
        })
        .catch((e) => {
          appInsights.trackException({ error: e});
          console.error(e);
        });
    }
    fetchData();
  }, []);
  if (data === undefined || Object.keys(data).length === 0) {
    return <Spinner />;
  } else {
    return (
      <div>
        <Layout
          headData="Activity Types"
          rowData={data}
          header="activitytype"
          keyData={[
            "activity",
            "createdOn",
            "createdBy",
            "modifiedOn",
            "modifiedBy",
          ]}
        />
      </div>
    );
  }
}

export default ActivityTypes;
