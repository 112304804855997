import React from "react";
import { useState, useEffect } from "react";
import { homeApi } from "../api";
import Layout from "./Layout";
import Spinner from "../../../src/spinner";
import { useOktaAuth } from "@okta/okta-react";
import {appInsights} from "../../appInsights";
function Tracks() {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      homeApi
        .getDropdownDetails("tracks", authState.accessToken.value)
        .then((res) => {
          setData(res);
        })
        .catch((e) => {
          appInsights.trackException({ error: e});
          console.error(e);
        });
    }
    fetchData();
  }, []);
  if (data === undefined || Object.keys(data).length === 0) {
    return <Spinner />;
  } else {
    return (
      <Layout
        headData="Tracks"
        rowData={data}
        header="tracks"
        keyData={[
          "title",
          "createdOn",
          "createdBy",
          "modifiedOn",
          "modifiedBy",
        ]}
      />
    );
  }
}

export default Tracks;
