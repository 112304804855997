import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const RejoinFacultyModal = ({
  show,
  handleClose,
  handleConfirm,
  facultyName,
  facultyId,
}) => {
  const [data, setData] = useState({ facultyId: facultyId });

  const handleOnChange = (e, key) => {
    setData((prev) => ({ ...prev, [key]: e.target.value }));
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose} centered>
      <Modal.Header>
        <b>Rejoin {facultyName}?</b>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleConfirm(data);
          }}
        >
          <Form.Group className="row m-3">
            <Form.Label className="col-md-4">
              Start Date
              <span className="p-0 asterik"> *</span>
            </Form.Label>
            <Form.Control
              className="col-md-8"
              type="date"
              placeholder="mm-dd-yyyy"
              required
              onChange={(e) => handleOnChange(e, "startDate")}
            />
          </Form.Group>
          <Form.Group className="me-2 d-flex justify-content-end">
            <Button variant="primary" type="submit">
              Rejoin
            </Button>
            <Button variant="outline-secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RejoinFacultyModal;
