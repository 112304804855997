import React, { useState, useEffect } from "react";
import { homeApi } from "../api";
import AdminTitle from "./CreateNewTablesData/AdminTitle";
import Ranks from "./CreateNewTablesData/Ranks";
import Promotion from "./CreateNewTablesData/Promotion";
import Mentor from "./CreateNewTablesData/Mentor";
import Visas from "./CreateNewTablesData/Visas";
import MedicalSchool from "./CreateNewTablesData/MedicalSchool";
import { useOktaAuth } from "@okta/okta-react";
import FacultyStatusChanges from "./CreateNewTablesData/FacultyStatusChanges";
import {
  MENTORS,
  TENURES,
  TRACKS,
  DESIGNATIONS,
  RANKS,
  STATUS,
  ADMINTITLETYPE,
  FISCALYEARS,
} from "../../config";
import PreEmployment from "./CreateNewTablesData/PreEmployment";
import Residency from "./CreateNewTablesData/Residencies";
import Internship from "./CreateNewTablesData/Internships";
import Fellowship from "./CreateNewTablesData/Fellowships";
import Termination from "./CreateNewTablesData/Termination";
import Cart from "./CreateNewTablesData/Cart";
import Accomplishments from "./CreateNewTablesData/Accomplishments";
import EditCart from "../EditFaculty/EditFacultyTables/EditCart";
import EditFacultyStatusChanges from "../EditFaculty/EditFacultyTables/EditFacultyStatusChanges";
import EditVisas from "../EditFaculty/EditFacultyTables/EditVisas";
import EditAdminTitle from "../EditFaculty/EditFacultyTables/EditAdminTitles";
import EditRanks from "../EditFaculty/EditFacultyTables/EditRanks";
import EditPromotions from "../EditFaculty/EditFacultyTables/EditPromotions";
import EditMentors from "../EditFaculty/EditFacultyTables/EditMentors";
import EditFellowship from "../EditFaculty/EditFacultyTables/EditFellowships";
import EditInternship from "../EditFaculty/EditFacultyTables/EditInternship";
import EditPreEmployment from "../EditFaculty/EditFacultyTables/EditPreEmployment";
import EditResidencies from "../EditFaculty/EditFacultyTables/EditResidencies";
import EditMedicalSchool from "../EditFaculty/EditFacultyTables/EditMedicalSchools";
import EditTermination from "../EditFaculty/EditFacultyTables/EditTerminations";
import EditAccomplishments from "../EditFaculty/EditFacultyTables/EditAccomplishments";
import Spinner from "../../spinner";
import { appInsights } from "../../appInsights";

const CreateNewTable = (props) => {
  var firstName = "",
    lastName = "";
  let type = "";
  if (props.type === "accomplishments") {
    type = "staffkeyinfo";
  } else {
    type = props.type;
  }
  const [data, setData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [dropdownActivity, setDropdownActivity] = useState([]);
  const [dropdownRank, setDropdownRanks] = useState([]);
  const [dropdownTenures, setDropdownTenures] = useState([]);
  const [dropdownTracks, setDropdownTracks] = useState([]);
  const [dropdownDesignation, setDropdownDesignations] = useState([]);
  const [dropdownStatus, setDropdownStatuses] = useState([]);
  const [dropdownTitleType, setDropdownAdminTitleType] = useState([]);
  const [dropdownFiscalYears, setDropdownFiscalYears] = useState([]);
  const [editFacultyData, setEditFacultyData] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth
        .getUser()
        .then((info) => {
          setUserInfo(info.name);
        })
        .catch();
    }

    homeApi
      .postDropDownFormDataList(
        [
          MENTORS,
          RANKS,
          DESIGNATIONS,
          TENURES,
          TRACKS,
          STATUS,
          ADMINTITLETYPE,
          FISCALYEARS,
        ],
        authState.accessToken.value
      )
      .then((response) => {
        setUserList(response.mentors);
        setDropdownDesignations(response.designations);
        setDropdownRanks(response.ranks);
        setDropdownTenures(response.tenures);
        setDropdownTracks(response.tracks);
        setDropdownStatuses(response.employmentStatuses);
        setDropdownAdminTitleType(response.administrativeTitleTypes);
        setDropdownFiscalYears(response.fiscalYears.reverse());
      })
      .catch((err) => {
        appInsights.trackException({ error: err });
        console.error(err);
      });

    const fetchData = async () => {
      homeApi
        .getFacultyDetail(props.id, authState.accessToken.value)
        .then((response) => {
          setData(response.faculty);
        })
        .catch((err) => console.error(err));
      if (props.linkType === "edit") {
        homeApi
          .getFacultyStatusChanges(
            props.tableid,
            type,
            authState.accessToken.value
          )
          .then((response) => {
            setEditFacultyData(response[0]);
          })
          .catch((err) => {
            appInsights.trackException({ error: err });
            console.error(err);
          });
      }

      homeApi
        .getDropdownDetails("activitytype", authState.accessToken.value)
        .then((response) => {
          setDropdownActivity(response);
        })
        .catch((err) => {
          appInsights.trackException({ error: err });
          console.log(err);
        });

      homeApi
        .getDropdownDetails("fiscalyears", authState.accessToken.value)
        .then((response) => {
          setDropdownFiscalYears(response);
        })
        .catch((err) => {
          appInsights.trackException({ error: err });
          console.error(err);
        });
    };

    fetchData();
  }, []);

  // let detailListFaculty = data.faculty;

  if (data && data.lastName) {
    lastName = data.lastName;
    firstName = ",".concat(data.firstName);
    //activity = facultyStatusChanges[0].title;
  }
  const nameValue = lastName.concat(firstName);
  if (props.linkType == "create") {
    if (data === undefined || Object.keys(data).length === 0) {
      return <Spinner />;
    } else {
      return (
        <>
          {props.type === "facultyStatusChanges" && (
            <FacultyStatusChanges
              lastName={nameValue}
              data={data}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              id={props.id}
            />
          )}
          {props.type === "adminTitles" && (
            <AdminTitle
              lastName={nameValue}
              createdBy={userInfo}
              id={props.id}
              dropdownAdminTitleType={dropdownTitleType}
            />
          )}

          {props.type === "promotions" && (
            <Promotion
              id={props.id}
              lastName={nameValue}
              createdBy={userInfo}
            />
          )}

          {props.type === "mentors" && (
            <Mentor
              lastName={nameValue}
              dropdown={userList}
              id={props.id}
              createdBy={userInfo}
            />
          )}

          {props.type === "visas" && (
            <Visas lastName={nameValue} id={props.id} createdBy={userInfo} />
          )}

          {props.type === "ranks" && (
            <Ranks
              lastName={nameValue}
              id={props.id}
              createdBy={userInfo}
              dropdownRank={dropdownRank}
              dropdownTenure={dropdownTenures}
              dropdownTrack={dropdownTracks}
              dropdownDesignation={dropdownDesignation}
              dropdownStatus={dropdownStatus}
            />
          )}

          {props.type === "medicalSchool" && (
            <MedicalSchool
              lastName={nameValue}
              id={props.id}
              createdBy={userInfo}
            />
          )}

          {props.type === "residencies" && (
            <Residency
              lastName={nameValue}
              id={props.id}
              createdBy={userInfo}
            />
          )}
          {props.type === "previousEmployment" && (
            <PreEmployment
              lastName={nameValue}
              id={props.id}
              createdBy={userInfo}
            />
          )}
          {props.type === "internships" && (
            <Internship
              lastName={nameValue}
              id={props.id}
              createdBy={userInfo}
            />
          )}
          {props.type === "fellowships" && (
            <Fellowship
              lastName={nameValue}
              id={props.id}
              createdBy={userInfo}
            />
          )}
          {props.type === "terminations" && (
            <Termination
              lastName={nameValue}
              id={props.id}
              createdBy={userInfo}
              startDate={data.uscHireDt}
            />
          )}
          {props.type === "cart" && (
            <Cart
              lastName={nameValue}
              id={props.id}
              createdBy={userInfo}
              dropdown={dropdownFiscalYears}
              // startDate={data.uscHireDt}
            />
          )}
          {props.type === "accomplishments" && (
            <Accomplishments
              lastName={nameValue}
              id={props.id}
              createdBy={userInfo}
              // startDate={data.uscHireDt}
            />
          )}
        </>
      );
    }
  } else {
    if (
      data === undefined ||
      Object.keys(data).length === 0 ||
      editFacultyData.length === 0
    ) {
      return <Spinner />;
    } else {
      return (
        <>
          {props.type === "facultyStatusChanges" && (
            <EditFacultyStatusChanges
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              id={props.id}
            />
          )}
          {props.type === "visas" && (
            <EditVisas
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              id={props.id}
            />
          )}
          {props.type === "admintitles" && (
            <EditAdminTitle
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              dropdownAdminTitleType={dropdownTitleType}
              id={props.id}
            />
          )}
          {props.type === "ranklogs" && (
            <EditRanks
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              id={props.id}
              dropdownRank={dropdownRank}
              dropdownTenure={dropdownTenures}
              dropdownTrack={dropdownTracks}
              dropdownDesignation={dropdownDesignation}
              dropdownStatus={dropdownStatus}
            />
          )}
          {props.type === "promotions" && (
            <EditPromotions
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              id={props.id}
            />
          )}
          {props.type === "mentors" && (
            <EditMentors
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              createdBy={userInfo}
              id={props.id}
              dropdown={userList}
            />
          )}
          {props.type === "fellowships" && (
            <EditFellowship
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              id={props.id}
            />
          )}
          {props.type === "internships" && (
            <EditInternship
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              id={props.id}
            />
          )}
          {props.type === "preemployment" && (
            <EditPreEmployment
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              id={props.id}
            />
          )}
          {props.type === "residencies" && (
            <EditResidencies
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              id={props.id}
            />
          )}
          {props.type === "medicalschools" && (
            <EditMedicalSchool
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              id={props.id}
            />
          )}
          {props.type === "terminations" && (
            <EditTermination
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              id={props.id}
              startDate={data.uscHireDt}
            />
          )}
          {props.type === "cart" && (
            <EditCart
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownFiscalYears}
              createdBy={userInfo}
              id={props.id}
              // startDate={data.uscHireDt}
            />
          )}
          {props.type === "accomplishments" && (
            <EditAccomplishments
              lastName={nameValue}
              tableid={props.tableid}
              dataTable={editFacultyData}
              dropdown={dropdownActivity}
              createdBy={userInfo}
              id={props.id}
              // startDate={data.uscHireDt}
            />
          )}
        </>
      );
    }
  }
};

export default CreateNewTable;
