import { useHistory } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { MenuItems } from "./MenuItems";
import Logo from "../../assets/butterfly.jpeg";

import "./Navbar.css";
let username;

const NavbarComponent = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  const login = async () => {
    oktaAuth.signInWithRedirect();
  };

  const logout = async () => {
    // oktaAuth.tokenManager.clear();
    // setTimeout(() => {
    //   window.location.href = "/";
    // }, 150);
    oktaAuth.signOut();
  };

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          if (info === undefined) {
            logout();
          } else {
            username = info.name;
            setUserInfo(info);
          }
        })
        .catch();
    }
  }, [authState.isAuthenticated]);

  if (authState.isPending) return null;

  const button = authState.isAuthenticated ? (
    <a className="btn nav-btn-auth" id="logout" onClick={logout}>
      Logout
    </a>
  ) : (
    <a className="btn nav-btn-auth" id="login" onClick={login}>
      Login
    </a>
  );

  return (
    <Navbar collapseOnSelect expand="lg">
      <div className="container-fluid">
        <img src={Logo} className="img_logo" alt="Image" />
        <Navbar.Brand className="navbar-a-link">
          Faculty Management System
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/">
                Faculty
              </a>
            </li>

            <>
              {MenuItems.subNav !== undefined && authState.isAuthenticated ? (
                <NavDropdown title={MenuItems.title} id="basic-nav-dropdown">
                  {MenuItems.subNav.map((item, index) => {
                    return (
                      <NavDropdown.Item href={item.path} key={index}>
                        {item.title}
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>
              ) : (
                <></>
              )}
            </>
          </ul>
          {authState.isAuthenticated && (
            <span className="navbar-text">
              Welcome: <a className="navbar-light">{username}</a>
            </span>
          )}
          {button}
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavbarComponent;
