import React, { useState } from "react";
import { Form, Row, Col, Button, FormControl } from "react-bootstrap";
import UploadAttachment from "./UploadAttachment";
import DateHelper from "../../helpers/DateHelper";
import { useLocation } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import "../styles.css";
import $ from "jquery";
import formatDate from "../../helpers/DateFormatter";
var userFormData = require("../../data/data.json");
var re = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
var reEmployeeId = new RegExp(/^\d{7}$/);
var reUscId = new RegExp(/^\d{10}$/);

const UserForm = ({ userList, onSubmit, detailList, formData = {} }) => {
  const DATE = DateHelper(new Date());
  const HIREDATE = DateHelper(new Date(formData.dob));
  const [data, setData] = useState(formData);
  const [errors, setErrors] = useState({});

  const DEFAULT_OPTION = "Please make a selection...";
  const DEFAULT_VALUE = "";
  let edit = false;
  const location = useLocation();
  if (location.pathname.includes("edit")) {
    edit = true;
  }

  const getUserKey = (key) => {
    if (data !== null) {
      const userKey = Object.keys(data).find(
        (k) => k.toLowerCase() === key.toLowerCase()
      );
      return userKey !== undefined ? userKey : key;
    } else return key;
  };

  const findFormErrors = () => {
    const { usc10id, employeeId, contactNumber } = data;
    const newErrors = {};

    // Employee ID errors
    // usc10id no errors
    if (employeeId !== "" && employeeId !== null) {
      if (!reEmployeeId.test(employeeId)) {
        newErrors.employeeId = "Invalid Employee ID!";
      }
    }

    // usc10id no errors
    if (usc10id !== "" && usc10id !== null) {
      if (!reUscId.test(usc10id)) {
        newErrors.usc10id = "Invalid USC 10 Digit Id!";
      }
    }

    // Contact no errors
    if (contactNumber !== "" && contactNumber !== null) {
      if (!re.test(contactNumber))
        newErrors.contactNumber = "Invalid Phone Number!";
    }

    return newErrors;
  };

  // SSN validation
  // trap keypress - only allow numbers
  $("input.ssn").on("keypress", function (event) {
    // trap keypress
    var character = String.fromCharCode(event.which);
    if (!isInteger(character)) {
      return false;
    }
  });

  // checks that an input string is an integer, with an optional +/- sign character
  function isInteger(s) {
    if (s === "-") return true;
    var isInteger_re = /^\s*(\+|-)?\d+\s*$/;
    return String(s).search(isInteger_re) != -1;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // get new errors
    const newErrors = findFormErrors();

    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
      console.error(errors);
    } else {
      onSubmit(data);
    }
  };

  const handleFileUpload = (file) => {
    setData({ ...data, imageRaw: file });
  };

  const handleOnChange = (type, e, key) => {
    let value;

    if (type === "checkbox") {
      value = e.currentTarget.checked;
      setData({
        ...data,
        [key]: processSelectedValue(key, value),
      });
    } else if (key === "affinityGroupId" || key === "ethnicityIds") {
      let values = e.map((val) => val.value);
      if (key in data) {
        data[key] = values;
        setData({
          ...data,
        });
      } else {
        setData({
          ...data,
          [key]: values,
        });
      }
    } else if (key === "ssn") {
      // value = e.target.value;
      // var val = value.replace(/\D/g, '');
      // var newVal = '';
      //   if(val.length > 4) {
      //       value = val;
      //   }
      //   if((val.length > 3) && (val.length < 6)) {
      //       newVal += val.substr(0, 3) + '-';
      //       val = val.substr(3);
      //   }
      //   if (val.length > 5) {
      //       newVal += val.substr(0, 3) + '-';
      //       newVal += val.substr(3, 2) + '-';
      //       val = val.substr(5);
      //   }
      //   newVal += val;
      //   value = newVal;

      // format SSN
      $("input.ssn").on("keyup", function () {
        var val = this.value.replace(/\D/g, "");
        var newVal = "";
        if (val.length > 4) {
          this.value = val;
        }
        if (val.length > 3 && val.length < 6) {
          newVal += val.substr(0, 3) + "-";
          val = val.substr(3);
        }
        if (val.length > 5) {
          newVal += val.substr(0, 3) + "-";
          newVal += val.substr(3, 2) + "-";
          val = val.substr(5);
        }
        newVal += val;
        this.value = newVal;
        setData({
          ...data,
          [key]: processSelectedValue(key, this.value),
        });
      });
    } else {
      value = e.target.value;
      setData({
        ...data,
        [key]: processSelectedValue(key, value),
      });
    }

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[key])
      setErrors({
        ...errors,
        [key]: null,
      });
  };

  const onRemove = (selectedList, removedItem ) => {
    handleOnChange("select", selectedList , "affinityGroupId");
  };
  const onRemoveEthnicity = (selectedList, removedItem ) => {
    handleOnChange("select", selectedList , "ethnicityIds");
  };

  const handleDefaultValue = (type, key) => {
    let finalValue = DEFAULT_VALUE;
    const value = data[key];
    // finalValue = value;
    if (type === "date") {
      finalValue = Boolean(value) ? formatDate(value) : DEFAULT_VALUE;
      // finalValue = formatDate(value);
      finalValue = processSelectedValue(key, finalValue);
    } else if (
      key === "affinityGroupId" &&
      value !== undefined &&
      value.length > 0
    ) {
      finalValue = affinityGroupId.value.filter((affinityGroup) => {
        return value.includes(affinityGroup.value);
      });
    } else if (
      key === "ethnicityIds" &&
      value !== undefined &&
      value !== null &&
      value.length > 0
    ) {
      finalValue = ethnicityIds.value.filter((ethnicity) => {
        return value.includes(ethnicity.value);
      }
      
      );
    } else {
      finalValue = value;
      finalValue = processSelectedValue(key, finalValue);
    }
    // finalValue = processSelectedValue(key, finalValue);
    if (!location.pathname.toLowerCase().includes("edit")) {
      finalValue = DEFAULT_VALUE;
    }

    return finalValue;
  };

  const processSelectedValue = (key, value) => {
    let result = value;
    switch (key) {
      case "primaryDeptId":
        result = userFormData.primaryDeptId.value.find(
          (v) => v.value === parseInt(value)
        );
        break;
      case "divisionId":
        result = userFormData.divisionId.value.find(
          (v) => v.value === parseInt(value)
        );

        break;
      case "ethnicityIds":
        result = userFormData.ethnicityIds.value.find(
          (v) => v.value === parseInt(value)
        );

        break;
      case "genderId":
        result = userFormData.genderId.value.find(
          (v) => v.value === parseInt(value)
        );

        break;
      case "employmentTypeId":
        result = userFormData.employmentTypeId.value.find(
          (v) => v.value === parseInt(value)
        );

        break;
      case "facultyTypeId":
        result = userFormData.facultyTypeId.value.find(
          (v) => v.value === parseInt(value)
        );

        break;
      case "affinityGroupsId":
        result = userFormData.affinityGroupsId.value.find(
          (v) => v.value === parseInt(value)
        );

        break;

      default:
    }
    if (result === null || result === undefined) {
      result = "";
    }
    return result.value === undefined ? result : result.value;
  };

  const { primaryDeptId } = userFormData;
  const { divisionId } = userFormData;
  const { ethnicityIds } = userFormData;
  const { genderId } = userFormData;
  const { employmentTypeId } = userFormData;
  const { facultyTypeId } = userFormData;
  const { affinityGroupId } = userFormData;  


  userFormData = {
    ...userFormData,
    primaryDeptId: {
      ...primaryDeptId,
      value: [{ id: "", department: DEFAULT_OPTION }]
        .concat(userList.departments)
        .map((department) => ({
          value: department.id,
          display: department.department,
        })),
    },
    divisionId: {
      ...divisionId,
      value: [{ id: "", title: DEFAULT_OPTION }]
        .concat(userList.divisions)
        .map((division) => ({
          value: division.id,
          display: division.title,
        })),
    },
    genderId: {
      ...genderId,
      value: [{ id: "", title: DEFAULT_OPTION }]
        .concat(userList.genders)
        .map((gender) => ({
          value: gender.id,
          display: gender.title,
        })),
    },
    employmentTypeId: {
      ...employmentTypeId,
      value: [{ id: "", type: DEFAULT_OPTION }]
        .concat(userList.employmentTypes)
        .map((employmentType) => ({
          value: employmentType.id,
          display: employmentType.type,
        })),
    },
    facultyTypeId: {
      ...facultyTypeId,
      value: [{ id: "", type: DEFAULT_OPTION }]
        .concat(userList.facultyTypes)
        .map((facultyType) => ({
          value: facultyType.id,
          display: facultyType.type,
        })),
    },
    affinityGroupId: {
      ...affinityGroupId,
      value: [].concat(userList.affinityGroups).map((affinityGroup) => ({
        value: affinityGroup.id,
        display: affinityGroup.title,
      })),
    },
    ethnicityIds: {
      ...ethnicityIds,
      value: []
        .concat(userList.ethnicities)
        .map((ethnicity) => ({
          value: ethnicity.id,
          display: ethnicity.title,
        })),
    },
  };

  return (
    <Form className="form-createNew " onSubmit={handleSubmit} id="createUser">
      {Object.entries(userFormData).map((field, index) => {
        const key = field[0];
        const userKey = getUserKey(key);
        const value = field[1];

        return (
          <Form.Group key={index} as={Row}>
            <Form.Label column sm={edit ? 4 : 2}>
              {value.display}
              {value.required ? <span className="p-0 asterik"> *</span> : null}
            </Form.Label>
            <Col sm={edit ? 8 : 6}>
              {value.type === "select" &&
              value.display === "Affinity Groups" || value.display === "Ethnicity" ? (
                <Multiselect
                  className= {edit ? 'editMultiSelect': 'addMultiSelect'}
                  placeholder="Please make a selection..."
                  displayValue="display"
                  required={value.required}
                  options={value.value}
                  selectedValues={handleDefaultValue(value.type, userKey)}
                  onRemove={userKey == 'affinityGroupId' ?  onRemove : onRemoveEthnicity}
                  onSelect={(e) => handleOnChange(value.type, e, userKey)}
                ></Multiselect>
              ) 
              : value.type === "select" ? (
                <Form.Control
                  as={value.type}
                  custom
                  required={value.required}
                  defaultValue={handleDefaultValue(value.type, userKey)}
                  onChange={(e) => handleOnChange(value.type, e, userKey)}
                >
                  {value.value.length !== 0
                    ? value.value.map((v, index) => (
                        <option value={v.value} key={index}>
                          {v.display}
                        </option>
                      ))
                    : null}
                </Form.Control>
              ) : value.type === "textarea" ? (
                <Form.Control
                  as={value.type}
                  required={value.required}
                  defaultValue={handleDefaultValue(value.type, userKey)}
                  onChange={(e) => handleOnChange(value.type, e, userKey)}
                />
              ) : value.type === "checkbox" ? (
                <Form.Check
                  required={value.required}
                  type={value.type}
                  defaultChecked={handleDefaultValue(value.type, userKey)}
                  onChange={(e) => handleOnChange(value.type, e, userKey)}
                />
              ) : value.display === "DOB" ? (
                <>
                  <Form.Control
                    // max={DATE}
                    // max={
                    //   data.dob !== undefined && data.dob !== null
                    //     ? data.dob.split("T")[0]
                    //     : ""
                    // }
                    // isInvalid={true}
                    onChange={(e) => handleOnChange(value.type, e, userKey)}
                    type={value.type}
                    placeholder="mm-dd-yyyy"
                    required={value.required}
                    defaultValue={handleDefaultValue(value.type, userKey)}
                  />
                  {/* <FormControl.Feedback type="invalid">
                    Date cannot exceed{" "}
                    {data.dob !== undefined && data.dob !== null
                      ? data.dob.split("T")[0]
                      : ""}
                  </FormControl.Feedback> */}
                </>
              ) : value.display === "USC Hire Date" ||
                value.display === "Officially Appointed" ||
                value.display === "Expiration Date" ? (
                <Form.Control
                  // min={
                  //   data.dob !== undefined && data.dob !== null
                  //     ? data.dob.split("T")[0]
                  //     : ""
                  // }
                  // max={data.uscHireDt}
                  // min={HIREDATE}
                  placeholder="mm-dd-yyyy"
                  onChange={(e) => handleOnChange(value.type, e, userKey)}
                  type={value.type}
                  required={value.required}
                  defaultValue={handleDefaultValue(value.type, userKey)}
                />
              ) : value.display === "Employee ID" ? (
                <>
                  <Form.Control
                    isInvalid={!!errors.employeeId}
                    onChange={(e) => handleOnChange(value.type, e, userKey)}
                    type={value.type}
                    required={value.required}
                    defaultValue={handleDefaultValue(value.type, userKey)}
                  />

                  <div className="text-danger">
                    {errors.employeeId ? (
                      <>
                        {window.scrollTo(0, 0)}
                        {errors.employeeId}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : value.display === "Contact Number" ? (
                <>
                  <Form.Control
                    isInvalid={!!errors.contactNumber}
                    onChange={(e) => handleOnChange(value.type, e, userKey)}
                    type={value.type}
                    maxLength="11"
                    required={value.required}
                    defaultValue={handleDefaultValue(value.type, userKey)}
                  />
                  <div className="text-danger">
                    {errors.contactNumber ? (
                      <>
                        {window.scrollTo(0, 0)} {errors.contactNumber}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : value.display === "Social Security" ? (
                <>
                  <Form.Control
                    className="ssn"
                    onChange={(e) => handleOnChange(value.type, e, userKey)}
                    type={value.type}
                    required={value.required}
                    maxLength="11"
                    defaultValue={handleDefaultValue(value.type, userKey)}
                  />
                </>
              ) : value.display === "USC 10 Digit ID" ? (
                <>
                  <Form.Control
                    isInvalid={!!errors.usc10id}
                    onChange={(e) => handleOnChange(value.type, e, userKey)}
                    type={value.type}
                    required={value.required}
                    defaultValue={handleDefaultValue(value.type, userKey)}
                  />
                  <div className="text-danger">
                    {errors.usc10id ? (
                      <>
                        {" "}
                        {window.scrollTo(0, 0)}
                        {errors.usc10id}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <Form.Control
                  onChange={(e) => handleOnChange(value.type, e, userKey)}
                  type={value.type}
                  required={value.required}
                  defaultValue={handleDefaultValue(value.type, userKey)}
                />
              )}
            </Col>
          </Form.Group>
        );
      })}
      <UploadAttachment files={handleFileUpload} />

      <Form.Group as={Row}>
        <Form.Label column sm={2}></Form.Label>
        <Col sm={3} className={`${edit ? `edit-submit` : ``}`}>
          <Button
            className="btn-create"
            variant="primary"
            type="submit"
            form="createUser"
          >
            Submit
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default UserForm;
