import React, { useContext } from "react";
import { Table, Form } from "react-bootstrap";
import { Link } from "@material-ui/core";
import { DetailContext } from "../../context/DetailContext";
import "../styles.css";

const FacultyInfo = () => {
  const { userList, detailList, id } = useContext(DetailContext);
  let affinityGroupValue, ethnicitiesValue;

  let detailListFaculty = detailList.faculty;

  if (Array.isArray(detailListFaculty.affinityGroupId)) {
    if (userList.affinityGroups !== undefined) {
      affinityGroupValue = userList.affinityGroups.filter((affinityGroup) => {
        return detailListFaculty.affinityGroupId.includes(affinityGroup.id);
      });
    }
  }

  if (Array.isArray(detailListFaculty.ethnicityIds)) {
    if (userList.ethnicities !== undefined) {
      ethnicitiesValue = userList.ethnicities.filter((ethnicity) => {
        return detailListFaculty.ethnicityIds.includes(ethnicity.id);
      });
    }
  }

  return (
    <>
      <div className="col-lg-4 col-md-5">
        <dl className="dl-horizontal">
          <dt>Last Name</dt>
          <dd>{detailListFaculty.lastName}</dd>
          <dt>First Name</dt>
          <dd>{detailListFaculty.firstName}</dd>
          <dt>Middle Initial</dt>
          <dd>{detailListFaculty.middleInitial}</dd>
          <dt>Suffix</dt>
          <dd>{detailListFaculty.suffix}</dd>
          <dt>Email</dt>
          <dd>{detailListFaculty.email}</dd>
          <dt>Years of Service</dt>
          <dd>{detailListFaculty.yearsOfService}</dd>
          <dt>Years Since Last Promotion</dt>
          <dd>{detailListFaculty.yearsOfPromotion}</dd>
          <dt>USC 10 Digit ID</dt>
          <dd>{detailListFaculty.usc10id}</dd>
          <dt>Employee ID</dt>
          <dd>{detailListFaculty.employeeId}</dd>
          <dt>DOB</dt>
          <dd>
            {detailListFaculty.dob != null &&
              detailListFaculty.dob.substring(0, 10)}
          </dd>
          <dt>CHLA PeopleSoft ID</dt>
          <dd>{detailListFaculty.peopleSoftId}</dd>
          <dt>Social Security</dt>
          <dd>{detailListFaculty.ssn}</dd>
          <dt>Location</dt>
          <dd>{detailListFaculty.location}</dd>
          <dt>Contact Number</dt>
          <dd>{detailListFaculty.contactNumber}</dd>
          <dt>Primary Dept</dt>
          <dd>{detailListFaculty.department}</dd>
          <dt>Division</dt>
          <dd>{detailListFaculty.division}</dd>
          <dt>Secondary Dept</dt>
          <dd>{detailListFaculty.secondaryDept}</dd>
          <dt>Expiration Date</dt>
          <dd>{detailListFaculty.secondaryDeptEndDt}</dd>
          <dt>Tertiary Dept</dt>
          <dd>{detailListFaculty.tertiaryDept}</dd>
          <dt>Expiration Date</dt>
          <dd>{detailListFaculty.tertiaryDeptEndDt}</dd>
          <dt>USC Hire Date/Dt</dt>
          <dd>{detailListFaculty.uscHireDt}</dd>
          <dt>Officially Appointed</dt>
          <dd>
            {detailListFaculty.officiallyAppointed != null &&
              detailListFaculty.officiallyAppointed.substring(0, 10)}
          </dd>
          <dt>Visiting</dt>
          <dd>
            <Form.Check
              type="checkbox"
              disabled
              checked={detailListFaculty.visiting}
            />
          </dd>
          <dt>Hispanic or Latino</dt>
          <dd>
            <Form.Check
              type="checkbox"
              disabled
              checked={detailListFaculty.isHispanicOrLatino}
            />
          </dd>
          <dt>Physician Scientist</dt>
          <dd>
            <Form.Check
              type="checkbox"
              disabled
              checked={detailListFaculty.physScientist}
            />
          </dd>
          <dt>Active</dt>
          <dd>
            <Form.Check
              type="checkbox"
              disabled
              checked={detailListFaculty.active}
            />
          </dd>
          <dt>Research Interests</dt>
          <dd>{detailListFaculty.researchInterests}</dd>

          <dt>Ethnicity</dt>
          {ethnicitiesValue === undefined ? (
            <dd></dd>
          ) : (
            <dd style={{ display: "grid" }}>
              {ethnicitiesValue.map((value) => (
                <span>{value.title}</span>
              ))}
            </dd>
          )}
          <dt>Affinity Groups</dt>
          {affinityGroupValue === undefined ? (
            <dd></dd>
          ) : (
            <dd style={{ display: "grid" }}>
              {affinityGroupValue.map((value) => (
                <span>{value.title}</span>
              ))}
            </dd>
          )}
          <dt>Gender</dt>
          <dd>{detailListFaculty.gender}</dd>
          <dt>Full Time/Part Time</dt>
          <dd>{detailListFaculty.employmentType}</dd>
          <dt>Percent</dt>
          <dd>{detailListFaculty.percent}</dd>
          <dt>Type</dt>
          <dd>{detailListFaculty.facultyType}</dd>
          <dt>Created By</dt>
          <dd>{detailListFaculty.createdBy}</dd>
          <dt>Created On</dt>
          <dd>{detailListFaculty.createdOn}</dd>
          <dt>Modified By</dt>
          <dd>{detailListFaculty.modifiedBy}</dd>
          <dt>Modified On</dt>
          <dd>{detailListFaculty.modifiedOn}</dd>
        </dl>
      </div>
    </>
  );
};

export default FacultyInfo;
