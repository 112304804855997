import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Form, Row, Col, Button } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { homeApi } from "../../api";
import Redirect from "../../DetailFaculty/CreateNewTablesData/Redirect";
import { useOktaAuth } from "@okta/okta-react";
import formatDate from "../../../helpers/DateFormatter";
import {appInsights} from "../../../appInsights";

function EditInternship(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState(props.dataTable);
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const id = props.tableid;
  const handleChange = ({ target: { name, value } }) => {
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const postBody = {
      ...data,
      modifiedBy: props.createdBy,
    };

    homeApi
      .putEditFacultyTable(
        "internships",
        postBody,
        id,
        authState.accessToken.value
      )
      .then((res) => {
        setRedirect(true);
        setMessage("200");
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      })
      .catch((e) => {
        setRedirect(true);
        setMessage("400");
        appInsights.trackException({ error: e});
        console.error(e);
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      });
  };
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail">Edit Internship</h2>
        <span className="span-link-btn">
          {" "}
          <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
            Back
          </Link>
        </span>
        <hr className="hr-facultyDetail"></hr>
        {redirect == true && <Redirect message={message} />}
        <Col xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Faculty Member
              </Form.Label>
              <Form.Control
                disabled
                className="activity_form_control"
                name="lastName"
                defaultValue={props.lastName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Internship *
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="internshipTitle"
                maxLength="100"
                required
                defaultValue={data.internshipTitle}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Specialty
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="specialty"
                maxLength="100"
                defaultValue={data.specialty}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Date From
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                type="date"
                name="dateFrom"
                // max={Boolean(data.dateTo) ? formatDate(data.dateTo) : ""}
                defaultValue={
                  Boolean(data.dateFrom) ? formatDate(data.dateFrom) : ""
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Date To
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                type="date"
                name="dateTo"
                // min={Boolean(data.dateFrom) ? formatDate(data.dateFrom) : ""}
                defaultValue={
                  Boolean(data.dateTo) ? formatDate(data.dateTo) : ""
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Col style={{ marginLeft: "inherit" }}>
                <Button className="activity_form_label" type="submit">
                  Save
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default EditInternship;
