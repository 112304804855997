import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Form, Row, Col, Button } from "react-bootstrap";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { homeApi } from "../../api";
import Redirect from "../../DetailFaculty/CreateNewTablesData/Redirect";
import { useOktaAuth } from "@okta/okta-react";
import { appInsights } from "../../../appInsights";
import Select from "react-select";

var firstName, lastName, fullName;


function EditMentors(props) {
  const { authState, oktaAuth } = useOktaAuth();
  const [data, setData] = useState(props.dataTable);
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const id = props.tableid;
  let optionNames = props.dropdown.map(x => ({label: x.lastName.concat(",", x.firstName), value: x.id}));
  const options = optionNames.sort((a,b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));


  const handleChange = (e ) => {
    if (e.label) {
      // let mentorFilter = props.dropdown.filter((x) => x.id === Number(value));
      // let mentorFirstName = mentorFilter[0].firstName;
      // const mentorName = mentorFilter[0].lastName.concat(",", mentorFirstName);
      setData({
        ...data,
        mentorName: e.label,
        mentorFacultyId: e.value
      });
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const postBody = {
      ...data,
      modifiedBy: props.createdBy,
    };

    homeApi
      .putEditFacultyTable("mentors", postBody, id, authState.accessToken.value)
      .then((res) => {
        setRedirect(true);
        setMessage("200");
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      })
      .catch((e) => {
        setRedirect(true);
        setMessage("400");
        appInsights.trackException({ error: e});
        console.error(e);
        setTimeout(() => {
          window.location.href = `/detail/${Number(props.id)}`;
        }, 1500);
      });
  };
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <h2 className="heading-facultyDetail">Edit Mentor Record</h2>
        <span className="span-link-btn">
          {" "}
          <Link className="new" to={{ pathname: `/detail/${props.id}` }}>
            Back
          </Link>
        </span>
        <hr className="hr-facultyDetail"></hr>
        {redirect == true && <Redirect message={message} />}

        <Col xs={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Faculty Member
              </Form.Label>
              <Form.Control
                disabled
                className="activity_form_control"
                name="lastName"
                defaultValue={props.lastName}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Mentor *
              </Form.Label>
              <Select options={options} 
              onChange={handleChange}
              style={{ backgroundColor: "white", appearance: "menulist" }}
              name="MentorFacultyId"
              placeholder="Please make a selection"
              className="activity_form_control"
              defaultValue={{ label: data.mentorName, value: data.mentorFacultyId}}
              />
          
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Mentor Other
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                name="otherMentor"
                maxLength="100"
                defaultValue={
                  data.otherMentor === "null" ? "" : data.otherMentor
                }
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label className="activity_form_label" column lg={4}>
                Notes
              </Form.Label>
              <Form.Control
                className="activity_form_control"
                as="textarea"
                rows={3}
                name="notes"
                maxLength="100"
                defaultValue={data.notes === "null" ? "" : data.notes}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Col style={{ marginLeft: "inherit" }}>
                <Button className="activity_form_label" type="submit">
                  Save
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default EditMentors;
